define('m03/routes/application', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({

    model: function model() {
      return Ember['default'].RSVP.hash({
        application: this.store.findAll('application'),
        projektdaten: this.store.findAll('projektdaten'),
        geometrie: this.store.findAll('geometrie'),
        bauteile: this.store.findAll('bauteile'),
        zwischenschichtverstaerkung: this.store.findAll('zwischenschichtverstaerkung'),
        anschlussgeometrie: this.store.findAll('anschlussgeometrie'),
        optionen: this.store.findAll('optionen'),
        lasteinwirkung: this.store.findAll('lasteinwirkung'),
        x3ddefault: this.store.findAll('x3ddefault')

      });
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      this.watchingTextSize(controller);
    },

    watchingTextSize: function watchingTextSize(controller) {
      var self = this;
      var viewingRay;
      var distance;

      Ember['default'].run.debounce(function () {
        console.log('/routes/application.js: Seite gerendert!');
        try {
          (function () {
            var setX3DSize = function setX3DSize() {
              var x3dGrafik = document.getElementById("threedee");
              var x3d = document.getElementById("x3d");
              var canvas = document.getElementsByTagName("canvas");
              var x3druntime = document.getElementById('x3d').runtime;

              // canvas[0].setAttribute('width', Number($(window).width()) - Number(x3dGrafik.offsetLeft) - 20);
              x3d.setAttribute('width', Number($(window).width()) - Number(x3dGrafik.offsetLeft) - 20);
              // canvas[0].setAttribute('hight', Number($(window).width()) - Number(x3dGrafik.offsetLeft) - 20);

              x3d.setAttribute('height', Number($(window).height()) - 250);
            };

            document.getElementById('x3d').runtime.enterFrame = function () {
              var skalfak = controller.model.x3ddefault.objectAt(0).get('skalierungsfaktor');
              var vp = controller.model.x3ddefault.objectAt(0).get('lastViewpoint');

              // distance = Number(controller.model.x3ddefault.objectAt(0).get('aktuelleDistance'));
              // var _11 = document.getElementById('x3d').runtime.projectionMatrix()._11;
              // skalfak = (distance/_11)/100;

              skalfak = Math.abs(document.getElementById(vp).getFieldValue('fieldOfView')[0]) / 4;

              // if (skalfak < 0.75){skalfak=0.75;}
              //begrenzt den Skalierungsfaktor, sodass nicht mehr als auf die dreifache größe skaliert wird
              // if (skalfak > 3){skalfak=3;}
              controller.model.x3ddefault.objectAt(0).set('skalierungsfaktor', skalfak);
              var texts = document.getElementsByTagName('text');
              var str = '';
              for (var j = 0; j < texts.length; j++) {
                str = texts[j].getAttribute('string');
                texts[j].setAttribute('string', ' ');
                texts[j].setAttribute('string', str);
              }
            };

            $(document).ready(function () {
              setX3DSize();
            });

            $(window).resize(function () {
              setX3DSize();
            });
          })();
        } catch (error) {
          console.log('/routes/application.js: x3dom need reload');
          x3dom.reload();
          self.watchingTextSize(controller);
        }
      }, 500);
    }

  });

});