define('m03/controllers/zwischenschichtverstaerkung', ['exports', 'ember', 'ember-validations', 'ember-i18n'], function (exports, Ember, EmberValidations, ember_i18n) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(EmberValidations['default'], {

    initTrigger: false,

    itw: false, //don't change this!
    swg: false, //don't change this!
    firm: "WUERTH", //don't change this!

    timberCode: "EC 5 NAD (D)", //don't change this!

    blass: false,
    werner: false,

    dickeBlass: "",
    dickeWerner: "",

    materialBlass: 13,
    materialWerner: 20,

    validations: {
      dickeBlass: {
        type: "l",
        tooltip: "ttDickeBlass",
        numericality: {
          greaterThanOrEqualTo: 1.8,
          lessThanOrEqualTo: 999.0
        }
      },
      dickeWerner: {
        type: "l",
        tooltip: "ttDickeWerner",
        numericality: {
          greaterThanOrEqualTo: 0.6,
          lessThanOrEqualTo: 999.0
        }
      }
    },

    ttDickeBlass: Ember['default'].computed('i18n.locale', {

      get: function get(key) {
        return this.get('i18n').t('wertebereich') + " 1.8 - 999.0 [cm]";
      },
      set: function set(key, value) {
        return value;
      }

    }),
    ttDickeWerner: Ember['default'].computed('i18n.locale', {

      get: function get(key) {
        return this.get('i18n').t('wertebereich') + " 0.6 - 999.0 [cm]";
      },
      set: function set(key, value) {
        return value;
      }

    }),

    i18n: Ember['default'].inject.service(),

    materialienBlass: [{
      name: "Vollholz Nadelholz",
      ind: 0
    }, {
      name: "Brettschichtholz homogen",
      ind: 1
    }, {
      name: "Brettschichtholz kombiniert",
      ind: 13
    }],

    fkl_vh: [{
      fklind: 0,
      fkl: "C14"
    }, {
      fklind: 1,
      fkl: "C16"
    }, {
      fklind: 2,
      fkl: "C18"
    }, {
      fklind: 3,
      fkl: "C20"
    }, {
      fklind: 4,
      fkl: "C22"
    }, {
      fklind: 5,
      fkl: "C24"
    }, {
      fklind: 6,
      fkl: "C27"
    }, {
      fklind: 7,
      fkl: "C30"
    }],

    fkl_bsh: [{
      fklind: 20,
      fkl: "GL24h"
    }, {
      fklind: 21,
      fkl: "GL28h"
    }, {
      fklind: 22,
      fkl: "GL32h"
    }, {
      fklind: 23,
      fkl: "GL36h"
    }],

    fkl_bsh_kombi: [{
      fklind: 30,
      fkl: "GL24c"
    }, {
      fklind: 31,
      fkl: "GL28c"
    }, {
      fklind: 32,
      fkl: "GL32c"
    }, {
      fklind: 33,
      fkl: "GL36c"
    }],

    starrVerschieblichTemp: [{
      id: true,
      name: "starr"
    }, {
      id: false,
      name: "verschieblich"
    }],

    fklassenBlass: [],
    fklBlass: 0,
    starr: true,

    vgebohrt: [],
    materialBlassPredrilled: false,
    materialWernerPredrilled: false,
    materialBlassLaubholz: false,
    materialWernerLaubholz: false,

    materialBlassBaubuche: false,

    variante1focused: true,
    variante2focused: false,

    //-- folgende werte nicht anpassen --
    laengenFaktor: 1, // 1 = default (cm). dieser wert ändert sich dynamisch und sollte nicht händisch geändert werden
    laengenLabel: "cm", // cm = default. dieser wert ändert sich dynamisch und sollte nicht händisch geändert werden
    laengenNachkomma: 1, // 1 = default (cm). dieser wert ändert sich dynamisch und sollte nicht händisch geändert werden
    syslaengenFaktor: 100, // 100 = default (m). dieser wert ändert sich dynamisch und sollte nicht händisch geändert werden
    syslaengenLabel: "m", // m = default. dieser wert ändert sich dynamisch und sollte nicht händisch geändert werden
    syslaengenNachkomma: 3, // 3 = default (m). dieser wert ändert sich dynamisch und sollte nicht händisch geändert werden
    kraefteFaktor: 1, // 1 = default (kN). dieser wert ändert sich dynamisch und sollte nicht händisch geändert werden
    kraefteLabel: "kN", // kN = default. dieser wert ändert sich dynamisch und sollte nicht händisch geändert werden
    kraefteNachkomma: 2, // 2 = default (kN). dieser wert ändert sich dynamisch und sollte nicht händisch geändert werden
    //--

    materialBlassHilfetext: "",
    materialBlassHilfetextFocused: false,
    fklBlassHilfetext: "",
    fklBlassHilfetextFocused: false,
    materialBlassPredrilledHilfetext: "",
    materialBlassPredrilledHilfetextFocused: false,
    dickeBlassHilfetext: "",
    dickeBlassHilfetextFocused: false,
    starrHilfetext: "",
    starrHilfetextFocused: false,
    materialWernerHilfetext: "",
    materialWernerHilfetextFocused: false,
    fklWernerHilfetext: "",
    fklWernerHilfetextFocused: false,
    materialWernerPredrilledHilfetext: "",
    materialWernerPredrilledHilfetextFocused: false,
    dickeWernerHilfetext: "",
    dickeWernerHilfetextFocused: false,

    init: function init() {
      this._super();

      var self = this;

      // this.set('fklassenBlass', self.getFklassen(0));
      this.set('fklBlass', this.getDefaultValue(0));
      // this.set('materialienBlass', self.getMaterials([0, 1, 13, 30, 21, 20, 7, 9, 8, 9999, 12]));
      // this.set('materialBlass', 0);

      // this.set('fklassenWerner', self.getFklassen(21));
      this.set('fklWerner', this.getDefaultValue(21));
      // this.set('materialienWerner', self.getMaterials([21, 7, 9, 20]));
      // this.set('materialWerner', 21);

      this.setSelectFieldsContent();
    },

    applyFactor: function applyFactor(m) {
      console.log("applyfactor(): " + this.laengenFaktor + " / " + this.kraefteFaktor);
      var result = "";
      for (var p in this[m]) {
        console.log("for");
        if (this[m].hasOwnProperty(p)) {
          console.log(p);
          if (this[m][p].type == "l") {
            console.log("laenge wird angepasst");
            this[m][p].numericality.lessThanOrEqualTo = parseFloat(this[m][p].numericality.lessThanOrEqualTo * this.laengenFaktor);
            this[m][p].numericality.greaterThanOrEqualTo = parseFloat(this[m][p].numericality.greaterThanOrEqualTo * this.laengenFaktor);
            // this.set(this[m][p].tooltip, this.get('i18n').t('wertebereich') + parseFloat(this[m][p].numericality.greaterThanOrEqualTo).toFixed(this.laengenNachkomma) + " - " +  parseFloat(this[m][p].numericality.lessThanOrEqualTo).toFixed(this.laengenNachkomma) + " [" + this.laengenLabel + "]");
          } else if (this[m][p].type == "sl") {
              console.log("systemlaenge wird angepasst");
              this[m][p].numericality.lessThanOrEqualTo = parseFloat(this[m][p].numericality.lessThanOrEqualTo * this.syslaengenFaktor);
              this[m][p].numericality.greaterThanOrEqualTo = parseFloat(this[m][p].numericality.greaterThanOrEqualTo * this.syslaengenFaktor);
              // this.set(this[m][p].tooltip, this.get('i18n').t('wertebereich') + parseFloat(this[m][p].numericality.greaterThanOrEqualTo).toFixed(this.syslaengenNachkomma) + " - " +  parseFloat(this[m][p].numericality.lessThanOrEqualTo).toFixed(this.syslaengenNachkomma) + " [" + this.syslaengenLabel + "]");
            } else if (this[m][p].type == "k") {
                console.log("kraft wird angepasst");
                this[m][p].numericality.lessThanOrEqualTo = parseFloat(this[m][p].numericality.lessThanOrEqualTo * this.kraefteFaktor);
                this[m][p].numericality.greaterThanOrEqualTo = parseFloat(this[m][p].numericality.greaterThanOrEqualTo * this.kraefteFaktor);
                // this.set(this[m][p].tooltip, this.get('i18n').t('wertebereich') + parseFloat(this[m][p].numericality.greaterThanOrEqualTo).toFixed(this.kraefteNachkomma) + " - " +  parseFloat(this[m][p].numericality.lessThanOrEqualTo).toFixed(this.kraefteNachkomma) + " [" + this.kraefteLabel + "]");
              }
        }
      }

      this.set('dickeBlass', 0..toFixed(this.laengenNachkomma));
      this.set('dickeWerner', 0..toFixed(this.laengenNachkomma));
    },

    setTooltips: function setTooltips(m) {

      console.log('##### setTooltips Zwischenschicht #####');

      var result = "";
      for (var p in this[m]) {
        if (this[m].hasOwnProperty(p)) {
          if (this[m][p].type == "l") {
            this.set(this[m][p].tooltip, this.get('i18n').t('wertebereich') + ' ' + parseFloat(this[m][p].numericality.greaterThanOrEqualTo).toFixed(this.laengenNachkomma) + " - " + parseFloat(this[m][p].numericality.lessThanOrEqualTo).toFixed(this.laengenNachkomma) + " [" + this.laengenLabel + "]");
          } else if (this[m][p].type == "sl") {
            this.set(this[m][p].tooltip, this.get('i18n').t('wertebereich') + ' ' + parseFloat(this[m][p].numericality.greaterThanOrEqualTo).toFixed(this.syslaengenNachkomma) + " - " + parseFloat(this[m][p].numericality.lessThanOrEqualTo).toFixed(this.syslaengenNachkomma) + " [" + this.syslaengenLabel + "]");
          } else if (this[m][p].type == "k") {
            this.set(this[m][p].tooltip, this.get('i18n').t('wertebereich') + ' ' + parseFloat(this[m][p].numericality.greaterThanOrEqualTo).toFixed(this.kraefteNachkomma) + " - " + parseFloat(this[m][p].numericality.lessThanOrEqualTo).toFixed(this.kraefteNachkomma) + " [" + this.kraefteLabel + "]");
          }
        }
      }
    },

    setValues: function setValues(values) {

      var self = this;

      if (self.debug) {
        console.log("values in zwischenschichtverstaerkung: ");
        console.log(values);
      }

      var dickeWernerEvent = {
        target: {
          name: "dickeWerner"
        }
      };
      var dickeBlassEvent = {
        target: {
          name: "dickeBlass"
        }
      };

      this.set('openFromFile', true);

      this.set('materialBlass', parseInt(values.materialBlass));
      this.set('fklBlass', parseInt(values.fklBlass));

      this.set('dickeBlass', (values.dickeBlass * self.laengenFaktor).toFixed(self.laengenNachkomma));
      this.setX3D((values.dickeBlass * self.laengenFaktor).toFixed(self.laengenNachkomma), dickeBlassEvent);

      this.set('starr', values.StarreZwischenSchicht);

      this.set('materialWerner', parseInt(values.materialWerner));
      this.set('fklWerner', parseInt(values.fklWerner));

      this.set('dickeWerner', (values.dickeWerner * self.laengenFaktor).toFixed(self.laengenNachkomma));
      this.setX3D((values.dickeWerner * self.laengenFaktor).toFixed(self.laengenNachkomma), dickeWernerEvent);

      this.send('validation');

      this.set('openFromFile', false);
    },

    getMaterials: function getMaterials(indexes) {

      var materialDB = materialienDB.materialien_de_m03;

      var tmp = [];

      for (var i = 0; i < indexes.length; i++) {
        for (var k = 0; k < materialDB.length; k++) {
          if (materialDB[k].id === indexes[i]) {
            tmp.push(materialDB[k]);
          }
        }
      }

      return tmp;
    },

    getFklassen: function getFklassen(matid) {

      var fklassenDB = fklDB_new;
      var fklassen = [];

      for (var p in fklassenDB) {
        if (fklassenDB.hasOwnProperty(p)) {
          if (fklassenDB[p].id === matid) {
            fklassen = fklassenDB[p].fklassen;
          }
        }
      }

      return fklassen;
    },

    getDefaultValue: function getDefaultValue(matid) {

      var fklassenDB = fklDB_new;
      var defaultValue = [];

      for (var p in fklassenDB) {
        if (fklassenDB.hasOwnProperty(p)) {
          if (fklassenDB[p].id === matid) {
            defaultValue = fklassenDB[p].fklassen[0].id;
          }
        }
      }

      if (matid === 0) {
        defaultValue = 5;
      }

      return defaultValue;
    },

    watchNumericalInputs: (function () {

      if (!this.openFromFile) {

        if (this.get('dickeBlass').indexOf(",") !== -1) {
          this.set('dickeBlass', this.get('dickeBlass').replace(",", "."));
        }

        if (this.get('dickeWerner').indexOf(",") !== -1) {
          this.set('dickeWerner', this.get('dickeWerner').replace(",", "."));
        }
      }
    }).observes('dickeBlass', 'dickeWerner'),

    watchBlassMaterial: (function () {
      var self = this;

      // if (self.get('materialBlass') === 12 || self.get('materialBlass') === 30) {
      //   self.set('materialBlassLaubholz', true);
      //   self.set('materialBlassPredrilled', true);
      // } else {
      //   self.set('materialBlassLaubholz', false);
      // }

      if (self.get('materialBlass') === 30) {
        self.set('materialBlassBaubuche', true);
      } else {
        self.set('materialBlassBaubuche', false);
      }

      self.set('materialBlassLaubholz', vorgebohrt.materialien.findBy('id', self.get('materialBlass')).laubholz);
      if (self.controllerFor('application').get('model').application.objectAt(0).get('firm') !== "ITW") {
        self.set('materialBlassPredrilled', vorgebohrt.materialien.findBy('id', self.get('materialBlass')).value);
      }

      this.set('fklassenBlass', self.getFklassen(self.get('materialBlass')));
      this.set('fklBlass', parseInt(self.getDefaultValue(self.get('materialBlass'))));

      var tmpMin = materialienDB.m03.findBy('firm', self.get('firm')).indices.zwischenschichtBlass.festigkeitsklassen.findBy('id', self.get('materialBlass')).minimalDicke;
      var tmpMax = materialienDB.m03.findBy('firm', self.get('firm')).indices.zwischenschichtBlass.festigkeitsklassen.findBy('id', self.get('materialBlass')).maximalDicke;

      console.log('tmpMin: ' + tmpMin + ' ? ' + tmpMin * self.laengenFaktor + ' ::: tmpMax: ' + tmpMax + ' ? ' + tmpMax * self.laengenFaktor);

      self.set('validations.dickeBlass.numericality.greaterThanOrEqualTo', tmpMin * self.laengenFaktor);
      self.set('validations.dickeBlass.numericality.lessThanOrEqualTo', tmpMax * self.laengenFaktor);
      self.set('ttDickeBlass', this.get('i18n').t('wertebereich') + ' ' + parseFloat(self.validations.dickeBlass.numericality.greaterThanOrEqualTo).toFixed(this.laengenNachkomma) + " - " + parseFloat(self.validations.dickeBlass.numericality.lessThanOrEqualTo).toFixed(this.laengenNachkomma) + " [" + this.laengenLabel + "]");

      this.setFklassenBlass();

      self.send("setSchraubenbildWerte", "Blass", "0", "90", "0", "variante1focused", "variante2focused");
    }).observes('materialBlass'),

    watchWernerMaterial: (function () {
      var self = this;

      // if (self.get('materialWerner') === 12 || self.get('materialWerner') === 30) {
      //   self.set('materialWernerLaubholz', true);
      //   self.set('materialWernerPredrilled', true);
      // } else {
      //   self.set('materialWernerLaubholz', false);
      // }

      self.set('materialWernerLaubholz', vorgebohrt.materialien.findBy('id', self.get('materialWerner')).laubholz);
      if (self.controllerFor('application').get('model').application.objectAt(0).get('firm') !== "ITW") {
        self.set('materialWernerPredrilled', vorgebohrt.materialien.findBy('id', self.get('materialWerner')).value);
      }

      this.set('fklassenWerner', self.getFklassen(self.get('materialWerner')));
      this.set('fklWerner', parseInt(self.getDefaultValue(self.get('materialWerner'))));

      var tmpMin = materialienDB.m03.findBy('firm', self.get('firm')).indices.verstaerkungWerner.festigkeitsklassen.findBy('id', self.get('materialWerner')).minimalDicke;
      var tmpMax = materialienDB.m03.findBy('firm', self.get('firm')).indices.verstaerkungWerner.festigkeitsklassen.findBy('id', self.get('materialWerner')).maximalDicke;

      self.set('validations.dickeWerner.numericality.greaterThanOrEqualTo', tmpMin * self.laengenFaktor);
      self.set('validations.dickeWerner.numericality.lessThanOrEqualTo', tmpMax * self.laengenFaktor);
      self.set('ttDickeWerner', this.get('i18n').t('wertebereich') + ' ' + parseFloat(self.validations.dickeWerner.numericality.greaterThanOrEqualTo).toFixed(this.laengenNachkomma) + " - " + parseFloat(self.validations.dickeWerner.numericality.lessThanOrEqualTo).toFixed(this.laengenNachkomma) + " [" + this.laengenLabel + "]");

      this.setFklassenWerner();
    }).observes('materialWerner'),

    /*setValues: function(values) {
      if (self.debug) {
        console.log("values in lasteinwirkung: " + values);
      }
       var dickeBlassEvent = {target: {name: "dickeBlass"}}; // dicke
      var dickeWernerEvent = {target: {name: "dickeWerner"}}; // dicke
       this.set('openFromFile', true);
       this.set('materialBlass', parseInt(values.materialBlass));
      this.set('fklBlass', parseInt(values.fklBlass));
      this.set('dickeBlass', String(values.dickeBlass));
      this.setX3D(parseInt(values.dickeBlass), dickeBlassEvent);
      this.set('starr', values.StarreZwischenSchicht);
      this.set('materialWerner', parseInt(values.materialWerner));
      this.set('fklWerner', parseInt(values.fklWerner));
      this.set('dickeWerner', String(values.dickeWerner));
      this.setX3D(parseInt(values.dickeWerner), dickeWernerEvent);
       this.send('validation');
       this.set('openFromFile', false);
     },*/

    setSelectFieldsContent: function setSelectFieldsContent() {
      var self = this;
      self.setMaterialBlass();
      self.setFklassenBlass();
      self.setStarrVerschieblich();
      self.setMaterialienWerner();
      self.setFklassenWerner();
      self.setVgebohrt();
    },

    setMaterialBlass: function setMaterialBlass() {
      var self = this;

      var indices = [0, 1, 13, 30, 21, 20, 7, 9, 8, 12, 9999];

      if (self.get('firm') === 'ITW' && self.get('timberCode') !== "EC 5 NAD (D)") {
        indices = materialienDB.m03.findBy('firm', self.get('firm')).indices.zwischenschichtBlass.materialien.findBy('id', 1).alternativeValues;
      } else {
        indices = materialienDB.m03.findBy('firm', self.get('firm')).indices.zwischenschichtBlass.materialien.findBy('id', 1).values;
      }

      self.set('materialienBlass', self.getSelectFieldContent('materialienDB', indices));
    },

    setFklassenBlass: function setFklassenBlass() {
      var self = this;
      var indices = [];

      indices = materialienDB.m03.findBy('firm', self.get('firm')).indices.zwischenschichtBlass.festigkeitsklassen.findBy('id', Number(this.get('materialBlass'))).values;
      self.set('fklBlass', materialienDB.m03.findBy('firm', self.get('firm')).indices.zwischenschichtBlass.festigkeitsklassen.findBy('id', Number(this.get('materialBlass'))).defaultValue);

      if (this.get('materialBlass') === 1) {
        // indices = [20, 21, 24, 22];
        // indices = materialienDB.m03.findBy('firm', self.get('firm')).indices.zwischenschichtBlass.festigkeitsklassen.findBy('id', Number(this.get('materialBlass'))).values;
        self.set('fklassenBlass', self.getSelectFieldContent('fkl_bsh', indices));
        // self.set('fklBlass', 20);
        // self.set('fklBlass', materialienDB.m03.findBy('firm', self.get('firm')).indices.zwischenschichtBlass.festigkeitsklassen.findBy('id', Number(this.get('materialBlass'))).defaultValue);
      } else if (self.get('materialBlass') === 13) {
          // indices = [30, 31, 34, 32];
          self.set('fklassenBlass', self.getSelectFieldContent('fkl_bsh_kombi', indices));
          // self.set('fklBlass', 30);
        } else if (this.get('materialBlass') === 0) {
            // indices = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
            self.set('fklassenBlass', self.getSelectFieldContent('fkl_vh', indices));
            // self.set('fklBlass', 5);
          } else if (this.get('materialBlass') === 30) {
              // indices = [301];
              self.set('fklassenBlass', self.getSelectFieldContent('fkl_baubuche', indices));
              // self.set('fklBlass', 301);
            } else if (this.get('materialBlass') === 21) {
                // indices = [110, 111, 112, 113];
                self.set('fklassenBlass', self.getSelectFieldContent('fkl_baufurniersperrholz', indices));
                // self.set('fklBlass', 110);
              } else if (this.get('materialBlass') === 20) {
                  // indices = [125, 126, 127];
                  self.set('fklassenBlass', self.getSelectFieldContent('fkl_osb_platten', indices));
                  // self.set('fklBlass', 125);
                } else if (this.get('materialBlass') === 7) {
                    // indices = [160, 170, 180, 190];
                    self.set('fklassenBlass', self.getSelectFieldContent('fkl_kunstharzgebundene_holzspanplatten', indices));
                    // self.set('fklBlass', 160);
                  } else if (this.get('materialBlass') === 9) {
                      // indices = [220];
                      self.set('fklassenBlass', self.getSelectFieldContent('fkl_holzfaserplatten', indices));
                      // self.set('fklBlass', 220);
                    } else if (this.get('materialBlass') === 8) {
                        // indices = [200];
                        self.set('fklassenBlass', self.getSelectFieldContent('fkl_zementgebundene_holzspanplatten', indices));
                        // self.set('fklBlass', 200);
                      } else if (this.get('materialBlass') === 12) {
                          // indices = [50, 51, 52];
                          self.set('fklassenBlass', self.getSelectFieldContent('fkl_vollholz_laubholz', indices));
                          // self.set('fklBlass', 50);
                        } else if (this.get('materialBlass') === 9999) {
                            // indices = [9999];
                            self.set('fklassenBlass', self.getSelectFieldContent('fkl_luft', indices));
                            // self.set('fklBlass', 9999);
                          } else if (this.get('materialBlass') === 22) {
                              self.set('fklassenBlass', self.getSelectFieldContent('fkl_gipskartonplatten', indices));
                            }
    },

    setStarrVerschieblich: function setStarrVerschieblich() {
      var self = this;
      var indices = [true, false];
      self.set('starrVerschieblich', self.getSelectFieldContent('starrVerschieblich', indices));
    },

    setMaterialienWerner: function setMaterialienWerner() {
      var self = this;
      var indices = [21, 7, 9, 20];
      indices = materialienDB.m03.findBy('firm', self.get('firm')).indices.verstaerkungWerner.materialien.findBy('id', 1).values;
      self.set('materialienWerner', self.getSelectFieldContent('materialienDB', indices));
    },

    setFklassenWerner: function setFklassenWerner() {
      var self = this;
      var indices = [];

      indices = materialienDB.m03.findBy('firm', self.get('firm')).indices.verstaerkungWerner.festigkeitsklassen.findBy('id', Number(this.get('materialWerner'))).values;
      self.set('fklWerner', materialienDB.m03.findBy('firm', self.get('firm')).indices.verstaerkungWerner.festigkeitsklassen.findBy('id', Number(this.get('materialWerner'))).defaultValue);

      if (this.get('materialWerner') === 21) {
        self.set('fklassenWerner', self.getSelectFieldContent('fkl_baufurniersperrholz', indices));
      } else if (self.get('materialWerner') === 7) {
        self.set('fklassenWerner', self.getSelectFieldContent('fkl_kunstharzgebundene_holzspanplatten', indices));
      } else if (this.get('materialWerner') === 9) {
        self.set('fklassenWerner', self.getSelectFieldContent('fkl_holzfaserplatten', indices));
      } else if (this.get('materialWerner') === 20) {
        self.set('fklassenWerner', self.getSelectFieldContent('fkl_osb_platten', indices));
      }
    },

    setVgebohrt: function setVgebohrt() {
      var self = this;
      var indices = [false, true];
      self.set('vgebohrt', self.getSelectFieldContent('vgebohrt', indices));
    },

    getSelectFieldContent: function getSelectFieldContent(db, indexes) {
      var self = this;
      var tmp = [];
      var dbContent = self.getDBContent(db);
      for (var i = 0; i < indexes.length; i++) {
        for (var k = 0; k < dbContent.length; k++) {
          if (dbContent[k].id === indexes[i]) {
            tmp.push({ id: dbContent[k].id, name: this.get('i18n').t(dbContent[k].name) });
          }
        }
      }

      return tmp;
    },

    getDBContent: function getDBContent(db) {
      var dbContent = [];
      switch (db) {
        case 'starrVerschieblich':
          dbContent = this.starrVerschieblichTemp;
          break;
        case 'materialienDB':
          dbContent = materialienDB.materialien_de_m03;
          break;
        case 'fkl_vh':
          dbContent = fklDB_new.fkl_vh.fklassen;
          break;
        case 'fkl_bsh':
          dbContent = fklDB_new.fkl_bsh.fklassen;
          break;
        case 'fkl_bsh_kombi':
          dbContent = fklDB_new.fkl_bsh_kombi.fklassen;
          break;
        case 'fkl_baubuche':
          dbContent = fklDB_new.fkl_baubuche.fklassen;
          break;
        case 'fkl_baufurniersperrholz':
          dbContent = fklDB_new.fkl_baufurniersperrholz.fklassen;
          break;
        case 'fkl_osb_platten':
          dbContent = fklDB_new.fkl_osb_platten.fklassen;
          break;
        case 'fkl_kunstharzgebundene_holzspanplatten':
          dbContent = fklDB_new.fkl_kunstharzgebundene_holzspanplatten.fklassen;
          break;
        case 'fkl_zementgebundene_holzspanplatten':
          dbContent = fklDB_new.fkl_zementgebundene_holzspanplatten.fklassen;
          break;
        case 'fkl_vollholz_laubholz':
          dbContent = fklDB_new.fkl_vollholz_laubholz.fklassen;
          break;
        case 'fkl_luft':
          dbContent = fklDB_new.fkl_luft.fklassen;
          break;
        case 'fkl_holzfaserplatten':
          dbContent = fklDB_new.fkl_holzfaserplatten.fklassen;
          break;
        case 'fkl_gipskartonplatten':
          dbContent = fklDB_new.fkl_gipskartonplatten.fklassen;
          break;
        case 'vgebohrt':
          dbContent = vorgebohrt.auswahlfeld;
          break;
      }
      return dbContent;
    },

    getValueFromSelectField: function getValueFromSelectField(contentArray, index) {
      var self = this;

      var tmp = "";
      var len = contentArray.length;

      for (var i = 0; i < len; i++) {
        if (contentArray[i].id === index) {
          tmp = contentArray[i].name;
        }
      }
      return tmp;
    },

    watchComboboxes: (function () {
      var self = this;

      self.resetVerbindungsmittel();

      // var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
      // applicationdata.set('treeLoaded', false);
      // applicationdata.set('initialized', false);
      // applicationdata.set('pdfErstellt', false);
      // applicationdata.set('dxfErstellt', false);
      //
      // var application = self.controllerFor('application');
      // application.set('verbindungsmittelInvalid', true);
      //
      // var downloadcenter = self.controllerFor('downloadcenter');
      // downloadcenter.set('pdfErstellt', false);
      // downloadcenter.set('dxfErstellt', false);

      var zv = this.controllerFor('application').get('model').zwischenschichtverstaerkung.objectAt(0);

      zv.set('materialBlass', self.get('materialBlass'));
      zv.set('fklBlass', self.get('fklBlass'));
      zv.set('StarreZwischenSchicht', self.get('starr'));
      zv.set('materialWerner', self.get('materialWerner'));
      zv.set('fklWerner', self.get('fklWerner'));

      if (self.get('blass')) {
        zv.set('MatKeyInterlayer', self.get('materialBlass'));
        zv.set('FKLKeyInterlayer', self.get('fklBlass'));
      }

      if (self.get('werner')) {
        zv.set('MatKeyInterlayer', self.get('materialWerner'));
        zv.set('FKLKeyInterlayer', self.get('fklWerner'));
      }

      self.controllerFor('lasteinwirkung').setNklassen();
      self.controllerFor('lasteinwirkung').send('validation', self.controllerFor('lasteinwirkung').get('winkelScherkraft'), { target: { name: "winkelScherkraft" } });
    }).observes('materialBlass', 'fklBlass', 'starr', 'materialWerner', 'fklWerner'),

    watchMaterialBlassPredrilled: (function () {

      var self = this;

      self.resetVerbindungsmittel();

      var zwischenschicht = self.controllerFor('application').get('model').zwischenschichtverstaerkung.objectAt(0);
      zwischenschicht.set('materialBlassPredrilled', self.get('materialBlassPredrilled'));
    }).observes('materialBlassPredrilled'),

    watchMaterialWernerPredrilled: (function () {

      var self = this;

      self.resetVerbindungsmittel();

      var zwischenschicht = self.controllerFor('application').get('model').zwischenschichtverstaerkung.objectAt(0);
      zwischenschicht.set('materialWernerPredrilled', self.get('materialWernerPredrilled'));
    }).observes('materialWernerPredrilled'),

    resetVerbindungsmittel: function resetVerbindungsmittel() {
      var self = this;

      var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
      applicationdata.set('treeLoaded', false);
      applicationdata.set('initialized', false);
      applicationdata.set('pdfErstellt', false);
      applicationdata.set('dxfErstellt', false);

      var application = self.controllerFor('application');
      application.set('verbindungsmittelInvalid', true);

      var downloadcenter = self.controllerFor('downloadcenter');
      downloadcenter.set('pdfErstellt', false);
      downloadcenter.set('dxfErstellt', false);
    },

    setX3D: function setX3D(value, event) {

      var application = this.controllerFor('application');
      var bauteilcontroller = this.controllerFor('bauteile');

      var x3d = application.get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');

      var self = this;

      x3d.set('aktuellerSchraubenTyp', 'standardSchraube');
      x3d.set('ergebnisGeladen', false);
      x3d.set('schraubenlisteAktualisiert', !x3d.get('schraubenlisteAktualisiert'));

      value = parseFloat(value).toFixed(2);

      switch (event.target.name) {
        case 'dickeBlass':
          if (this.errors.dickeBlass.length === 0) {
            // x3d.set('dickeBlass', value / self.laengenFaktor);
            x3d.set('dickeBlass', Number(value) / Number(this.get('laengenFaktor')));
            x3d.set('dickeBlassEingetragen', true);

            bauteile.findBy('id', 'verstaerkungBlass1').get('boxsizes').objectAt(0).set('z', Number(value) / Number(this.get('laengenFaktor')));
            bauteile.findBy('id', 'verstaerkungBlass2').get('boxsizes').objectAt(0).set('z', Number(value) / Number(this.get('laengenFaktor')));

            bauteilcontroller.setSchraubenlaenge();
          } else {
            x3d.set('dickeBlassEingetragen', false);
          }
          break;
        case 'dickeWerner':
          if (this.errors.dickeBlass.length === 0) {
            // x3d.set('dickeWerner', value / self.laengenFaktor);
            x3d.set('dickeWerner', value / Number(this.get('laengenFaktor')));
            x3d.set('dickeWernerEingetragen', true);

            bauteile.findBy('id', 'verstaerkungWerner1').get('boxsizes').objectAt(0).set('z', Number(value) / Number(this.get('laengenFaktor')));
            bauteile.findBy('id', 'verstaerkungWerner2').get('boxsizes').objectAt(0).set('z', Number(value) / Number(this.get('laengenFaktor')));

            bauteilcontroller.setSchraubenlaenge();
          } else {
            x3d.set('dickeWernerEingetragen', false);
          }
          break;
      }

      x3d.set('transformHelper', !x3d.get('transformHelper'));
      self.controllerFor('application').zentriertObjekt();
    },

    actions: {

      validation: function validation(value, event) {

        var self = this;

        if (!self.openFromFile) {
          this.setX3D(value, event);
        }

        self.validate().then(function () {
          // all validations pass

        })['catch'](function () {
          // any validations fail

        })['finally'](function () {

          var validation = self.get('isValid');
          var application = self.controllerFor('application');

          if (validation === true) {
            self.set('displayErrors', false);
            application.set('zwischenschichtVerstaerkungInvalid', false);

            self.resetVerbindungsmittel();

            // var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
            // applicationdata.set('treeLoaded', false);
            // applicationdata.set('initialized', false);
            // applicationdata.set('pdfErstellt', false);
            // applicationdata.set('dxfErstellt', false);
            //
            // var downloadcenter = self.controllerFor('downloadcenter');
            // downloadcenter.set('pdfErstellt', false);
            // downloadcenter.set('dxfErstellt', false);
            //
            // application.set('verbindungsmittelInvalid', true);

            var zv = self.controllerFor('application').get('model').zwischenschichtverstaerkung.objectAt(0);

            console.log('Setzt Werte dickeBlass und dickeWerner');

            zv.set('dickeBlass', (parseFloat(self.get('dickeBlass')) / self.laengenFaktor).toFixed(2));
            zv.set('dickeWerner', (parseFloat(self.get('dickeWerner')) / self.laengenFaktor).toFixed(2));
          } else {
            self.set('displayErrors', true);
            // application.set('errorsInvalid', true);
            application.set('zwischenschichtVerstaerkungInvalid', true);
          }
        });
      },

      dickeBlassIsSelected: function dickeBlassIsSelected() {
        var dickeBlassAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('dickeBlassAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('dickeBlassAktiv', !dickeBlassAktiv);

        if (this.get('dickeBlass') !== "") {
          var valuework = parseFloat(this.get('dickeBlass').replace(",", "."));
          var valuerounded = valuework.toFixed(this.laengenNachkomma);
          this.set('dickeBlass', valuerounded);
          this.send('validation', valuerounded, {
            target: {
              name: "dickeBlass"
            }
          });
        }

        if (dickeBlassAktiv === false) {
          document.getElementsByName('dickeBlass')[0].setSelectionRange(0, this.get('dickeBlass').length);
        }
      },

      dickeWernerIsSelected: function dickeWernerIsSelected() {
        var dickeWernerAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('dickeWernerAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('dickeWernerAktiv', !dickeWernerAktiv);

        if (this.get('dickeWerner') !== "") {
          var valuework = parseFloat(this.get('dickeWerner').replace(",", "."));
          var valuerounded = valuework.toFixed(this.laengenNachkomma);
          this.set('dickeWerner', valuerounded);
          this.send('validation', valuerounded, {
            target: {
              name: "dickeWerner"
            }
          });
        }

        if (dickeWernerAktiv === false) {
          document.getElementsByName('dickeWerner')[0].setSelectionRange(0, this.get('dickeWerner').length);
        }
      },

      setSchraubenbildWerte: function setSchraubenbildWerte(te, _alphaRAD_KraftFaser, _alphaRAD_vmFaser, _betaRAD_vmFurnier, focusedButton, unfocusedButton) {

        var self = this;

        self.resetVerbindungsmittel();

        var zwischenschichtverstaerkung = this.controllerFor('application').get('model').zwischenschichtverstaerkung.objectAt(0);

        zwischenschichtverstaerkung.set(te + '_alphaRAD_KraftFaser', _alphaRAD_KraftFaser);
        zwischenschichtverstaerkung.set(te + '_alphaRAD_vmFaser', _alphaRAD_vmFaser);
        zwischenschichtverstaerkung.set(te + '_betaRAD_vmFurnier', _betaRAD_vmFurnier);

        self.set(focusedButton, true);
        self.set(unfocusedButton, false);
      },

      removeClass: function removeClass(element) {
        var self = this;
        self.set(element, false);
      }
    }, //Ende Actions

    // hilfeTexte observes

    observes_materialBlassHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Zwischenschichtverstaerkung", "materialBlass", self.get('materialBlass').toString());
      self.controllerFor("supercontroller").setHilfetexte("zwischenschichtverstaerkung", hilfetextArray);
    }).observes('materialBlass', 'initTrigger'),

    observes_fklBlassHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Zwischenschichtverstaerkung", "fklBlass", "0");
      self.controllerFor("supercontroller").setHilfetexte("zwischenschichtverstaerkung", hilfetextArray);
    }).observes('fklBlass', 'initTrigger'),

    observes_materialBlassPredrilledHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Zwischenschichtverstaerkung", "materialBlassPredrilled", "0");
      self.controllerFor("supercontroller").setHilfetexte("zwischenschichtverstaerkung", hilfetextArray);
    }).observes('materialBlassPredrilled', 'initTrigger'),

    observes_dickeBlassHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Zwischenschichtverstaerkung", "dickeBlass", "0");
      self.controllerFor("supercontroller").setHilfetexte("zwischenschichtverstaerkung", hilfetextArray);
    }).observes('dickeBlass', 'initTrigger'),

    observes_starrHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Zwischenschichtverstaerkung", "starr", "0");
      self.controllerFor("supercontroller").setHilfetexte("zwischenschichtverstaerkung", hilfetextArray);
    }).observes('starr', 'initTrigger'),

    observes_materialWernerHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Zwischenschichtverstaerkung", "materialWerner", "0");
      self.controllerFor("supercontroller").setHilfetexte("zwischenschichtverstaerkung", hilfetextArray);
    }).observes('materialWerner', 'initTrigger'),

    observes_fklWernerHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Zwischenschichtverstaerkung", "fklWerner", "0");
      self.controllerFor("supercontroller").setHilfetexte("zwischenschichtverstaerkung", hilfetextArray);
    }).observes('fklWerner', 'initTrigger'),

    observes_materialWernerPredrilledHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Zwischenschichtverstaerkung", "materialWernerPredrilled", "0");
      self.controllerFor("supercontroller").setHilfetexte("zwischenschichtverstaerkung", hilfetextArray);
    }).observes('materialWernerPredrilled', 'initTrigger'),

    observes_dickeWernerHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Zwischenschichtverstaerkung", "dickeWerner", "0");
      self.controllerFor("supercontroller").setHilfetexte("zwischenschichtverstaerkung", hilfetextArray);
    }).observes('dickeWerner', 'initTrigger'),

    // hilfeTexte display properties

    display_materialBlassHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('materialBlassHilfetext'));;
    }).property('materialBlassHilfetext'),

    display_fklBlassHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('fklBlassHilfetext'));;
    }).property('fklBlassHilfetext'),

    display_materialBlassPredrilledHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('materialBlassPredrilledHilfetext'));;
    }).property('materialBlassPredrilledHilfetext'),

    display_dickeBlassHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('dickeBlassHilfetext'));;
    }).property('dickeBlassHilfetext'),

    display_starrHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('starrHilfetext'));;
    }).property('starrHilfetext'),

    display_materialWernerHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('materialWernerHilfetext'));;
    }).property('materialWernerHilfetext'),

    display_fklWernerHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('fklWernerHilfetext'));;
    }).property('fklWernerHilfetext'),

    display_materialWernerPredrilledHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('materialWernerPredrilledHilfetext'));;
    }).property('materialWernerPredrilledHilfetext'),

    display_dickeWernerHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('dickeWernerHilfetext'));;
    }).property('dickeWernerHilfetext')

  });

});