define('m03/models/anschlussgeometrie', ['exports', 'ember-data'], function (exports, DS) {

	'use strict';

	var anschlussgeometrie = DS['default'].Model.extend({

		ueberstandDiagonaleVertikal: DS['default'].attr('string'),
		winkelAbschnittDiagonaleGRAD: DS['default'].attr('string'),
		winkelEndAbschnittDiagonaleGRAD: DS['default'].attr('string'),
		//winkelEndAbschnittGRAD: DS.attr('string'),
		exzentrizitaetDiagonale: DS['default'].attr('string'),
		ALFA_Elements_GRAD: DS['default'].attr('string'),
		ueberstandDiagonaleBerechnen: DS['default'].attr('boolean'),
		abschnittDiagonaleBerechnen: DS['default'].attr('boolean'),

		abschnittRechts: DS['default'].attr('boolean'),
		abschnittLinks: DS['default'].attr('boolean'),

		a1: DS['default'].attr('string'),
		a2: DS['default'].attr('string'),
		alpha1: DS['default'].attr('string'),
		alpha2: DS['default'].attr('string'),
		alpha3: DS['default'].attr('string'),

		abschnittOben: DS['default'].attr('boolean'),
		abschnittUnten: DS['default'].attr('boolean'),

		b1: DS['default'].attr('string'),
		b2: DS['default'].attr('string'),
		beta1: DS['default'].attr('string'),
		beta2: DS['default'].attr('string'),
		beta3: DS['default'].attr('string')

	});

	anschlussgeometrie.reopenClass({
		FIXTURES: [{
			id: 1,
			ueberstandDiagonaleVertikal: "0",
			winkelAbschnittDiagonaleGRAD: "90",
			winkelEndAbschnittDiagonaleGRAD: "90",
			//winkelEndAbschnittGRAD: 1,
			exzentrizitaetDiagonale: "0",
			ALFA_Elements_GRAD: "0.0",
			ueberstandDiagonaleBerechnen: false,
			abschnittDiagonaleBerechnen: false,

			abschnittRechts: true,
			abschnittLinks: false,

			a1: 0,
			a2: 0,
			alpha1: 90,
			alpha2: 90,
			alpha3: 180,

			abschnittOben: true,
			abschnittUnten: false,

			b1: 0,
			b2: 0,
			beta1: 90,
			beta2: 90,
			beta3: 180

		}]
	});

	exports['default'] = anschlussgeometrie;

});