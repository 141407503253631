define('m03/controllers/geometrie', ['exports', 'ember', 'ember-validations', 'ember-i18n'], function (exports, Ember, EmberValidations, ember_i18n) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(EmberValidations['default'], {

    initTrigger: false,

    itw: false, //don't change this!
    swg: false, //don't change this!

    zwvsauswahlanzeigen: true, // toggle um die auswahl für die zwischenschicht anzuzeigen oder auszublenden

    displayErrors: true,
    openFromFile: false,
    stahlholz: false,
    sflaechendisabled: false,
    schichtfolgedisabled: false,
    zwischenschichtAuswahlDisabled: false,
    scherflaechenA: 1,
    scherflaechenVM: 1,
    schichtfolge: 1,
    setztiefe: '',
    zwischenschichtVerstaerkung: 0,
    vorgebohrt: false,
    klammerWinkel: true,

    //-- folgende werte nicht anpassen --
    laengenFaktor: 1, // 1 = default (cm). dieser wert ändert sich dynamisch und sollte nicht händisch geändert werden
    laengenLabel: "cm", // cm = default. dieser wert ändert sich dynamisch und sollte nicht händisch geändert werden
    laengenNachkomma: 1, // 1 = default (cm). dieser wert ändert sich dynamisch und sollte nicht händisch geändert werden
    syslaengenFaktor: 100, // 100 = default (m). dieser wert ändert sich dynamisch und sollte nicht händisch geändert werden
    syslaengenLabel: "m", // m = default. dieser wert ändert sich dynamisch und sollte nicht händisch geändert werden
    syslaengenNachkomma: 3, // 3 = default (m). dieser wert ändert sich dynamisch und sollte nicht händisch geändert werden
    kraefteFaktor: 1, // 1 = default (kN). dieser wert ändert sich dynamisch und sollte nicht händisch geändert werden
    kraefteLabel: "kN", // kN = default. dieser wert ändert sich dynamisch und sollte nicht händisch geändert werden
    kraefteNachkomma: 2, // 2 = default (kN). dieser wert ändert sich dynamisch und sollte nicht händisch geändert werden
    //--

    ttSetztiefe: Ember['default'].computed('i18n.locale', {

      get: function get(key) {
        return this.get('i18n').t('wertebereich') + " 0.0 - 5.0 [cm]";
      },
      set: function set(key, value) {
        return value;
      }

    }),

    i18n: Ember['default'].inject.service(),

    validations: {
      setztiefe: {
        type: "l",
        tooltip: "ttSetztiefe",
        numericality: {
          greaterThanOrEqualTo: 0.0,
          lessThanOrEqualTo: 5.0
        }
      }
    },

    sflaechenVM: [],

    sflaechenA: [],

    sfolge: [],

    vgebohrt: [],

    kWinkel: [],

    zschichtVerstaerkung: [],

    disabledValuesInZschichtVerstaerkung: [],
    disabledValuesInSFlaechenVM: [],
    disabledValuesInSFlaechenA: [],
    disabledValuesInSchichtfolge: [],

    scherflaechenVMHilfetext: "",
    scherflaechenVMHilfetextFocused: false,
    scherflaechenAHilfetext: "",
    scherflaechenAHilfetextFocused: false,
    schichtfolgeHilfetext: "",
    schichtfolgeHilfetextFocused: false,
    setztiefeHilfetext: "",
    setztiefeHilfetextFocused: false,
    klammerWinkelHilfetext: "",
    klammerWinkelHilfetextFocused: false,
    zwischenschichtVerstaerkungHilfetext: "",
    zwischenschichtVerstaerkungHilfetextFocused: false,

    init: function init() {

      this._super();

      /*var application = this.controllerFor('application');
      this.set('laengenFaktor', application.get('laengenFaktor'));
      this.set('laengenLabel', application.get('laengenLabel'));
      this.set('kraftFaktor', application.get('kraftFaktor'));
      this.set('kraftLabel', application.get('kraftLabel'));*/

      // this.applyFactor("validations");

      this.set('setztiefe', "0.0");

      this.setSelectFieldsContent();
    },

    applyFactor: function applyFactor(m) {
      console.log("applyfactor(): " + this.laengenFaktor + " / " + this.kraftFaktor);
      var result = "";
      for (var p in this[m]) {
        console.log("for");
        if (this[m].hasOwnProperty(p)) {
          console.log(p);
          if (this[m][p].type == "l") {
            console.log("laenge wird angepasst");
            this[m][p].numericality.lessThanOrEqualTo = parseFloat(this[m][p].numericality.lessThanOrEqualTo * this.laengenFaktor);
            this[m][p].numericality.greaterThanOrEqualTo = parseFloat(this[m][p].numericality.greaterThanOrEqualTo * this.laengenFaktor);
            // this.set(this[m][p].tooltip, this.get('i18n').t('wertebereich') + parseFloat(this[m][p].numericality.greaterThanOrEqualTo).toFixed(this.laengenNachkomma) + " - " +  parseFloat(this[m][p].numericality.lessThanOrEqualTo).toFixed(this.laengenNachkomma) + " [" + this.laengenLabel + "]");
          } else if (this[m][p].type == "sl") {
              console.log("systemlaenge wird angepasst");
              this[m][p].numericality.lessThanOrEqualTo = parseFloat(this[m][p].numericality.lessThanOrEqualTo * this.syslaengenFaktor);
              this[m][p].numericality.greaterThanOrEqualTo = parseFloat(this[m][p].numericality.greaterThanOrEqualTo * this.syslaengenFaktor);
              // this.set(this[m][p].tooltip, this.get('i18n').t('wertebereich') + parseFloat(this[m][p].numericality.greaterThanOrEqualTo).toFixed(this.syslaengenNachkomma) + " - " +  parseFloat(this[m][p].numericality.lessThanOrEqualTo).toFixed(this.syslaengenNachkomma) + " [" + this.syslaengenLabel + "]");
            } else if (this[m][p].type == "k") {
                console.log("kraft wird angepasst");
                this[m][p].numericality.lessThanOrEqualTo = parseFloat(this[m][p].numericality.lessThanOrEqualTo * this.kraefteFaktor);
                this[m][p].numericality.greaterThanOrEqualTo = parseFloat(this[m][p].numericality.greaterThanOrEqualTo * this.kraefteFaktor);
                // this.set(this[m][p].tooltip, this.get('i18n').t('wertebereich') + parseFloat(this[m][p].numericality.greaterThanOrEqualTo).toFixed(this.kraefteNachkomma) + " - " +  parseFloat(this[m][p].numericality.lessThanOrEqualTo).toFixed(this.kraefteNachkomma) + " [" + this.kraefteLabel + "]");
              }
        }
      }
    },

    setTooltips: function setTooltips(m) {

      console.log('##### setTooltips Geometrie #####');

      var result = "";
      for (var p in this[m]) {
        if (this[m].hasOwnProperty(p)) {
          if (this[m][p].type == "l") {
            this.set(this[m][p].tooltip, this.get('i18n').t('wertebereich') + ' ' + parseFloat(this[m][p].numericality.greaterThanOrEqualTo).toFixed(this.laengenNachkomma) + " - " + parseFloat(this[m][p].numericality.lessThanOrEqualTo).toFixed(this.laengenNachkomma) + " [" + this.laengenLabel + "]");
          } else if (this[m][p].type == "sl") {
            this.set(this[m][p].tooltip, this.get('i18n').t('wertebereich') + ' ' + parseFloat(this[m][p].numericality.greaterThanOrEqualTo).toFixed(this.syslaengenNachkomma) + " - " + parseFloat(this[m][p].numericality.lessThanOrEqualTo).toFixed(this.syslaengenNachkomma) + " [" + this.syslaengenLabel + "]");
          } else if (this[m][p].type == "k") {
            this.set(this[m][p].tooltip, this.get('i18n').t('wertebereich') + ' ' + parseFloat(this[m][p].numericality.greaterThanOrEqualTo).toFixed(this.kraefteNachkomma) + " - " + parseFloat(this[m][p].numericality.lessThanOrEqualTo).toFixed(this.kraefteNachkomma) + " [" + this.kraefteLabel + "]");
          }
        }
      }
    },

    setValues: function setValues(values) {

      var self = this;

      if (self.debug) {
        console.log("values in bauteile: ");
        console.log(values);
      }

      var setztiefeEvent = {
        target: {
          name: "setztiefe"
        }
      };
      this.setX3D(parseInt((values.Setztiefe * self.laengenFaktor).toFixed(self.laengenNachkomma)), setztiefeEvent);

      this.set('openFromFile', true);

      this.set('scherflaechenVM', parseInt(values.scherflaechenVM));
      console.log('scherflaechenVM');

      this.set('scherflaechenA', parseInt(values.scherflaechenA));
      console.log('scherflaechenA');

      this.set('schichtfolge', parseInt(values.schichtfolge));
      console.log('schichtfolge');

      this.set('vorgebohrt', values.Predrilled);
      this.set('setztiefe', (values.Setztiefe * self.laengenFaktor).toFixed(self.laengenNachkomma));

      this.set('klammerWinkel', values.Klammer30);
      console.log('klammerWinkel');

      this.set('zwischenschichtVerstaerkung', parseInt(values.zwischenschichtVerstaerkung));
      console.log('zwischenschichtVerstaerkung');

      this.send('validation');

      this.set('openFromFile', false);
    },

    setSelectFieldsContent: function setSelectFieldsContent() {
      var self = this;
      self.setSflaechenVM();
      self.setSflaechenA();
      self.setSfolge();
      self.setVgebohrt();
      self.setKWinkel();
      self.setZschichtVerstaerkung();
    },

    setSflaechenVM: function setSflaechenVM() {
      var self = this;
      var indices = [1, 2];
      self.set('sflaechenVM', self.getSelectFieldContent('sflaechenVM', indices));
    },

    setSflaechenA: function setSflaechenA() {
      var self = this;
      var indices = [1, 2];
      self.set('sflaechenA', self.getSelectFieldContent('sflaechenA', indices));
    },

    setSfolge: function setSfolge() {
      var self = this;
      var indices = [1, 2];

      self.set('sfolge', self.getSelectFieldContent('sfolge', indices));
    },

    setVgebohrt: function setVgebohrt() {
      var self = this;
      var indices = [false, true];
      self.set('vgebohrt', self.getSelectFieldContent('vgebohrt', indices));
    },

    setKWinkel: function setKWinkel() {
      var self = this;
      var indices = [true, false];
      self.set('kWinkel', self.getSelectFieldContent('kWinkel', indices));
    },

    setZschichtVerstaerkung: function setZschichtVerstaerkung() {
      var self = this;
      var indices = [0, 1, 2];
      self.set('zschichtVerstaerkung', self.getSelectFieldContent('zschichtVerstaerkung', indices));
    },

    getSelectFieldContent: function getSelectFieldContent(db, indexes) {
      var self = this;
      var tmp = new Array();
      var dbContent = self.getDBContent(db);
      for (var i = 0; i < indexes.length; i++) {
        for (var k = 0; k < dbContent.length; k++) {
          if (dbContent[k].id === indexes[i]) {
            if (db === 'zschichtVerstaerkung' && self.get('disabledValuesInZschichtVerstaerkung').indexOf(dbContent[k].id) !== -1 || db === 'sflaechenVM' && self.get('disabledValuesInSFlaechenVM').indexOf(dbContent[k].id) !== -1 || db === 'sflaechenA' && self.get('disabledValuesInSFlaechenA').indexOf(dbContent[k].id) !== -1 || db === 'sfolge' && self.get('disabledValuesInSchichtfolge').indexOf(dbContent[k].id) !== -1) {
              tmp.push(new Object({
                id: dbContent[k].id,
                name: this.get('i18n').t(dbContent[k].name),
                disabled: true
              }));
            } else {
              tmp.push(new Object({
                id: dbContent[k].id,
                name: this.get('i18n').t(dbContent[k].name),
                disabled: false
              }));
            }
          }
        }
      }

      return tmp;
    },

    getDBContent: function getDBContent(db) {
      var dbContent = [];
      switch (db) {
        case 'sflaechenVM':
          dbContent = scherflaechenDB.sflaechenVM;
          break;
        case 'sflaechenA':
          dbContent = scherflaechenDB.sflaechenA;
          break;
        case 'sfolge':
          dbContent = scherflaechenDB.sfolge;
          break;
        case 'vgebohrt':
          dbContent = vorgebohrt.auswahlfeld;
          break;
        case 'kWinkel':
          dbContent = scherflaechenDB.kWinkel;
          break;
        case 'zschichtVerstaerkung':
          dbContent = scherflaechenDB.zschichtVerstaerkung;
          break;
      }
      return dbContent;
    },

    getValueFromSelectField: function getValueFromSelectField(contentArray, index) {
      var self = this;

      var tmp = "";
      var len = contentArray.length;

      for (var i = 0; i < len; i++) {
        if (contentArray[i].id === index) {
          tmp = contentArray[i].name;
        }
      }
      return tmp;
    },

    watchNumericalInputs: (function () {

      if (!this.openFromFile) {

        if (this.get('setztiefe').indexOf(",", ".") !== -1) {
          this.set('setztiefe', this.get('setztiefe').replace(",", "."));
          this.setfuehrendeNull('setztiefe');
        }
      }
    }).observes('setztiefe'),

    setfuehrendeNull: function setfuehrendeNull(ind) {
      if (this.get(ind).charAt(0) === '.') {
        this.set(ind, '0.');
      }
    },

    resetVerbindungsmittel: function resetVerbindungsmittel() {
      var self = this;
      var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
      applicationdata.set('treeLoaded', false);
      applicationdata.set('initialized', false);
      applicationdata.set('pdfErstellt', false);
      applicationdata.set('dxfErstellt', false);

      var application = self.controllerFor('application');
      application.set('verbindungsmittelInvalid', true);

      var downloadcenter = self.controllerFor('downloadcenter');
      downloadcenter.set('pdfErstellt', false);
      downloadcenter.set('dxfErstellt', false);

      var x3d = self.controllerFor('application').get('model').x3ddefault.objectAt(0);
      x3d.set('aktuellerSchraubenTyp', 'standardSchraube');
      x3d.set('ergebnisGeladen', false);
      x3d.set('schraubenlisteAktualisiert', !x3d.get('schraubenlisteAktualisiert'));
    },

    watchSchichtfolge: (function () {
      var self = this;
      var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
      var application = self.controllerFor('application');
      var x3d = self.controllerFor('application').get('model').x3ddefault.objectAt(0);
      var lasteinwirkung = self.controllerFor('lasteinwirkung');
      var bauteile = self.controllerFor('bauteile');

      self.resetVerbindungsmittel();

      if (self.get('schichtfolge') === 2) {
        self.set('stahlholz', true);
        self.set('setztiefe', '0.0');
        x3d.set('sFolgeHolzHolz', false);

        if (self.get('disabledValuesInSFlaechenVM').indexOf(2) === -1) {
          self.addValueToArray('disabledValuesInSFlaechenVM', 2);
        }

        if (self.get('scherflaechenVM') === 2) {
          bauteile.set('htStahl', false);
          bauteile.set('ntStahl', true);
        } else {
          bauteile.set('htStahl', true);
          bauteile.set('ntStahl', false);
          lasteinwirkung.set('stahl', true);
          application.set('stahl', true);
        }
      } else {

        if (self.get('zwischenschichtVerstaerkung') !== 1) {
          self.removeValueFromArray('disabledValuesInSFlaechenVM', 2);
        }

        self.set('stahlholz', false);
        lasteinwirkung.set('stahl', false);
        application.set('stahl', false);
        bauteile.set('htStahl', false);
        bauteile.set('ntStahl', false);
        // self.set('setztiefe', '');
        x3d.set('sFolgeHolzHolz', true);
      }

      self.setValuesToModel();
    }).observes('schichtfolge'),

    watchScherflaechenVM: (function () {
      var self = this;
      var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
      var application = self.controllerFor('application');
      var x3d = self.controllerFor('application').get('model').x3ddefault.objectAt(0);
      var lasteinwirkung = self.controllerFor('lasteinwirkung');
      var bauteile = self.controllerFor('bauteile');

      self.resetVerbindungsmittel();

      if (self.get('scherflaechenVM') === 2) {
        self.set('scherflaechenA', 2);

        self.addValueToArray('disabledValuesInSchichtfolge', 2);

        if (self.get('itw') === false) {
          self.set('schichtfolgedisabled', true);
          self.set('schichtfolge', 1);
        }

        self.set('sflaechendisabled', true);
        //self.set('schichtfolge', 1);

        if (self.get('zwischenschichtVerstaerkung') === 1) {
          self.set('zwischenschichtVerstaerkung', 0);
        }

        x3d.set('zweiSchnittig', true);
        x3d.set('zweiSchaerflaechen', true);
      } else {

        self.removeValueFromArray('disabledValuesInSchichtfolge', 2);

        self.set('sflaechendisabled', false);
        self.set('schichtfolgedisabled', false);

        x3d.set('zweiSchnittig', false);
      }

      self.setValuesToModel();
    }).observes('scherflaechenVM'),

    watchScherflaechenA: (function () {
      var self = this;
      var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
      var application = self.controllerFor('application');
      var x3d = self.controllerFor('application').get('model').x3ddefault.objectAt(0);
      var lasteinwirkung = self.controllerFor('lasteinwirkung');
      var bauteile = self.controllerFor('bauteile');

      self.resetVerbindungsmittel();

      if (self.get('scherflaechenA') === 2) {
        x3d.set('zweiSchaerflaechen', true);
        self.addValueToArray('disabledValuesInZschichtVerstaerkung', 1);
      } else {
        x3d.set('zweiSchaerflaechen', false);
        self.removeValueFromArray('disabledValuesInZschichtVerstaerkung', 1);
      }

      self.setValuesToModel();
    }).observes('scherflaechenA'),

    watchKlammerWinkel: (function () {
      var self = this;
      var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
      var application = self.controllerFor('application');
      var x3d = self.controllerFor('application').get('model').x3ddefault.objectAt(0);
      var lasteinwirkung = self.controllerFor('lasteinwirkung');
      var bauteile = self.controllerFor('bauteile');

      self.resetVerbindungsmittel();
      self.setValuesToModel();
    }).observes('klammerWinkel'),

    watchZwischenschichtVerstaerkung: (function () {
      var self = this;
      var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
      var application = self.controllerFor('application');
      var x3d = self.controllerFor('application').get('model').x3ddefault.objectAt(0);
      var lasteinwirkung = self.controllerFor('lasteinwirkung');
      var bauteile = self.controllerFor('bauteile');

      var zv = self.controllerFor('zwischenschichtverstaerkung');
      var geometrie = this.controllerFor('application').get('model').geometrie.objectAt(0);

      self.resetVerbindungsmittel();

      if (self.get('zwischenschichtVerstaerkung') === 0) {
        console.log("keine zwischenschicht!");
        zv.set('blass', false);
        zv.set('werner', false);
        geometrie.set('ZwischenSchichtVorh', false);
        geometrie.set('VerstaerkungVorh', false);
        application.set('zwverDisabled', true);
        lasteinwirkung.set('zsver', 0);
        application.set('zsver', 0);
        x3d.set('verstaerkungBlass', false);
        x3d.set('verstaerkungWerner', false);

        if (self.get('schichtfolge') !== 2) {
          self.removeValueFromArray('disabledValuesInSFlaechenVM', 2);
        }

        self.removeValueFromArray('disabledValuesInSFlaechenA', 2);
      } else if (self.get('zwischenschichtVerstaerkung') === 1) {
        console.log("BLASS!");
        zv.set('blass', true);
        zv.set('werner', false);
        zv.set('validations.dickeWerner.numericality.greaterThanOrEqualTo', 0.0);
        zv.set('validations.dickeBlass.numericality.greaterThanOrEqualTo', 18.0);

        geometrie.set('ZwischenSchichtVorh', true);
        geometrie.set('VerstaerkungVorh', false);
        application.set('werner', false);
        application.set('zwverDisabled', false);
        lasteinwirkung.set('zsver', 1);
        application.set('zsver', 1);
        x3d.set('verstaerkungBlass', true);
        x3d.set('verstaerkungWerner', false);

        var zvdata = self.controllerFor('application').get('model').zwischenschichtverstaerkung.objectAt(0);
        zvdata.set('materialBlass', '0');
        zvdata.set('fklBlass', '5');
        zv.set('materialBlass', 0);
        zv.set('fklBlass', 5);

        zv.watchBlassMaterial();

        zv.send('validation', zv.get('dickeBlass'), { target: { name: "dickeBlass" } });

        self.set('scherflaechenVM', 1);

        if (self.get('disabledValuesInSFlaechenVM').indexOf(2) === -1) {
          self.addValueToArray('disabledValuesInSFlaechenVM', 2);
        }

        self.set('scherflaechenA', 1);
        self.addValueToArray('disabledValuesInSFlaechenA', 2);
      } else {
        console.log("WERNER!");
        zv.set('blass', false);
        zv.set('werner', true);
        zv.set('validations.dickeBlass.numericality.greaterThanOrEqualTo', 0.0);
        zv.set('validations.dickeWerner.numericality.greaterThanOrEqualTo', 6.0);
        geometrie.set('ZwischenSchichtVorh', false);
        geometrie.set('VerstaerkungVorh', true);
        application.set('werner', true);
        application.set('zwverDisabled', false);
        lasteinwirkung.set('zsver', 2);
        application.set('zsver', 2);
        x3d.set('verstaerkungBlass', true);
        x3d.set('verstaerkungWerner', true);

        var zvdata = self.controllerFor('application').get('model').zwischenschichtverstaerkung.objectAt(0);
        zvdata.set('materialWerner', '21');
        zvdata.set('fklWerner', '110');
        zv.set('materialWerner', 21);
        zv.set('fklWerner', 110);

        zv.watchWernerMaterial();

        zv.send('validation', zv.get('dickeWerner'), { target: { name: "dickeWerner" } });

        if (self.get('schichtfolge') !== 2) {
          self.removeValueFromArray('disabledValuesInSFlaechenVM', 2);
        }

        self.removeValueFromArray('disabledValuesInSFlaechenA', 2);
      }
      self.setValuesToModel();
    }).observes('zwischenschichtVerstaerkung'),

    setValuesToModel: function setValuesToModel() {

      var self = this;
      var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
      var application = self.controllerFor('application');
      var x3d = self.controllerFor('application').get('model').x3ddefault.objectAt(0);
      var lasteinwirkung = self.controllerFor('lasteinwirkung');
      var bauteile = self.controllerFor('bauteile');
      var geometrie = this.controllerFor('application').get('model').geometrie.objectAt(0);

      self.send('validation', self.setztiefe, { target: { name: "setztiefe" } });
      self.schnittigkeitSetzen();

      self.setSflaechenVM();
      self.setSflaechenA();
      self.setSfolge();
      self.setZschichtVerstaerkung();

      bauteile.setSchraubenlaenge();
      bauteile.setDisplaySchalungTragUndKonterlattung();

      geometrie.set('Klammer30', self.get('klammerWinkel'));
      geometrie.set('zwischenschichtVerstaerkung', self.get('zwischenschichtVerstaerkung'));
      geometrie.set('scherflaechenA', self.get('scherflaechenA'));
      geometrie.set('scherflaechenVM', self.get('scherflaechenVM'));
      geometrie.set('schichtfolge', self.get('schichtfolge'));
    },

    setNutzungsklassen: (function () {
      this.controllerFor('lasteinwirkung').setNklassen();
    }).observes('zwischenschichtVerstaerkung'),

    watchVorgebohrt: (function () {

      var self = this;

      var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
      applicationdata.set('treeLoaded', false);
      applicationdata.set('initialized', false);
      applicationdata.set('pdfErstellt', false);
      applicationdata.set('dxfErstellt', false);

      var application = self.controllerFor('application');
      application.set('verbindungsmittelInvalid', true);

      var downloadcenter = self.controllerFor('downloadcenter');
      downloadcenter.set('pdfErstellt', false);
      downloadcenter.set('dxfErstellt', false);

      var bauteileModel = this.controllerFor('application').get('model').bauteile.objectAt(0);
      bauteileModel.set('TimberElement1Predrilled', self.get('vorgebohrt'));
      bauteileModel.set('TimberElement2Predrilled', self.get('vorgebohrt'));

      var zwischenschichtModel = this.controllerFor('application').get('model').zwischenschichtverstaerkung.objectAt(0);
      zwischenschichtModel.set('materialBlassPredrilled', self.get('vorgebohrt'));
      zwischenschichtModel.set('materialWernerPredrilled', self.get('vorgebohrt'));

      var geometrie = this.controllerFor('application').get('model').geometrie.objectAt(0);
      geometrie.set('Predrilled', self.get('vorgebohrt'));
      var bauteile = this.controllerFor('bauteile');
      if (this.get('vorgebohrt')) {
        bauteile.set('laubholz', false);
      } else {
        bauteile.set('laubholz', true);
      }
    }).observes('vorgebohrt'),

    schnittigkeitSetzen: function schnittigkeitSetzen() {

      console.log('schnittigkeitSetzen');
      console.log('openFromFile: ' + this.get('openFromFile'));

      var self = this;

      var sfvm = self.get('scherflaechenVM');
      var sfa = self.get('scherflaechenA');
      var sf = self.get('schichtfolge');

      var geometrie = this.controllerFor('application').get('model').geometrie.objectAt(0);
      var x3d = self.controllerFor('application').get('model').x3ddefault.objectAt(0);

      var bauteile = this.controllerFor('bauteile');

      if (sfvm === 1 && sfa === 1 && sf === 1) {
        console.log("1 + HH");
        geometrie.set('ShearPlaneMultiplicator', '1');
        geometrie.set('ConnectionType', 'HH');
        bauteile.set('materialienOption', 1);
        if (self.get('openFromFile') === false) {
          bauteile.set('htholz', 0);
          bauteile.set('htfkl', 5);
          bauteile.set('ntholz', 0);
          bauteile.set('ntfkl', 5);
        }
        this.setHTgrafik(10, {
          rot: 0.52,
          gruen: 0.385,
          blau: 0.02
        });
        this.setNTgrafik(10, {
          rot: 0.52,
          gruen: 0.385,
          blau: 0.02
        });
      } else if (sfvm === 1 && sfa === 1 && sf === 2) {
        console.log("1 + HS");
        geometrie.set('ShearPlaneMultiplicator', '1');
        geometrie.set('ConnectionType', 'SH');
        bauteile.set('materialienOption', 2);
        if (self.get('openFromFile') === false) {
          bauteile.set('htholz', 1000);
          bauteile.set('htfkl', 23.5);
          bauteile.set('ntholz', 0);
          bauteile.set('ntfkl', 5);
        }
        this.setHTgrafik(2, {
          rot: 0,
          gruen: 0,
          blau: 1
        });
        this.setNTgrafik(10, {
          rot: 0.52,
          gruen: 0.385,
          blau: 0.02
        });
      } else if (sfvm === 1 && sfa === 2 && sf === 1) {
        console.log("2 + HH");
        geometrie.set('ShearPlaneMultiplicator', '2');
        geometrie.set('ConnectionType', 'HH');
        bauteile.set('materialienOption', 3);
        if (self.get('openFromFile') === false) {
          bauteile.set('htholz', 0);
          bauteile.set('htfkl', 5);
          bauteile.set('ntholz', 0);
          bauteile.set('ntfkl', 5);
        }
        this.setHTgrafik(10, {
          rot: 0.52,
          gruen: 0.385,
          blau: 0.02
        });
        this.setNTgrafik(10, {
          rot: 0.52,
          gruen: 0.385,
          blau: 0.02
        });
      } else if (sfvm === 2 && sfa === 2 && sf === 1) {
        console.log("1 + HHH");
        geometrie.set('ShearPlaneMultiplicator', '1');
        geometrie.set('ConnectionType', 'HHH');
        bauteile.set('materialienOption', 4);
        if (self.get('openFromFile') === false) {

          console.log('openFromFile: ' + self.get('openFromFile'));

          bauteile.set('htholz', 0);
          bauteile.set('htfkl', 5);
          bauteile.set('ntholz', 0);
          bauteile.set('ntfkl', 5);
        }
        this.setHTgrafik(10, {
          rot: 0.52,
          gruen: 0.385,
          blau: 0.02
        });
        this.setNTgrafik(10, {
          rot: 0.52,
          gruen: 0.385,
          blau: 0.02
        });
      } else if (sfvm === 1 && sfa === 2 && sf === 2) {
        console.log("2 + SH");
        geometrie.set('ShearPlaneMultiplicator', '2');
        geometrie.set('ConnectionType', 'SH');
        bauteile.set('materialienOption', 5);
        if (self.get('openFromFile') === false) {
          bauteile.set('htholz', 1000);
          bauteile.set('htfkl', 23.5);
          bauteile.set('ntholz', 0);
          bauteile.set('ntfkl', 5);
        }
        this.setHTgrafik(2, {
          rot: 0,
          gruen: 0,
          blau: 1
        });
        this.setNTgrafik(10, {
          rot: 0.52,
          gruen: 0.385,
          blau: 0.02
        });
      } else if (sfvm === 2 && sfa === 2 && sf === 2) {
        console.log("1 + HSH");
        geometrie.set('ShearPlaneMultiplicator', '1');
        geometrie.set('ConnectionType', 'HSH');
        bauteile.set('materialienOption', 6);
        if (self.get('openFromFile') === false) {
          bauteile.set('htholz', 0);
          bauteile.set('htfkl', 5);
          bauteile.set('ntholz', 1000);
          bauteile.set('ntfkl', 23.5);
        }
        this.setHTgrafik(10, {
          rot: 0.52,
          gruen: 0.385,
          blau: 0.02
        });
        this.setNTgrafik(2, {
          rot: 0,
          gruen: 0,
          blau: 1
        });
      }

      bauteile.setSelectFieldsContent();
    },

    setHTgrafik: function setHTgrafik(breite, farbe) {
      var application = this.controllerFor('application');
      var x3d = application.get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');
      var ht1 = bauteile.findBy('id', 'hauptTraeger1');
      var ht2 = bauteile.findBy('id', 'hauptTraeger2');

      if (!x3d.get('htBreiteEingetragen')) {
        ht1.get('boxsizes').objectAt(0).set('z', breite);
        ht2.get('boxsizes').objectAt(0).set('z', breite);
      }

      ht1.get('diffusecolors').objectAt(0).set('rot', farbe.rot);
      ht1.get('diffusecolors').objectAt(0).set('gruen', farbe.gruen);
      ht1.get('diffusecolors').objectAt(0).set('blau', farbe.blau);

      ht2.get('diffusecolors').objectAt(0).set('rot', farbe.rot);
      ht2.get('diffusecolors').objectAt(0).set('gruen', farbe.gruen);
      ht2.get('diffusecolors').objectAt(0).set('blau', farbe.blau);

      x3d.set('transformHelper', !this.get('transformHelper'));
    },

    setNTgrafik: function setNTgrafik(breite, farbe) {
      var application = this.controllerFor('application');
      var x3d = application.get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');
      var nt = bauteile.findBy('id', 'nebenTraeger');

      if (!x3d.get('ntBreiteEingetragen')) {
        nt.get('boxsizes').objectAt(0).set('z', breite);
      }

      nt.get('diffusecolors').objectAt(0).set('rot', farbe.rot);
      nt.get('diffusecolors').objectAt(0).set('gruen', farbe.gruen);
      nt.get('diffusecolors').objectAt(0).set('blau', farbe.blau);

      x3d.set('transformHelper', !this.get('transformHelper'));
    },

    setX3D: function setX3D(value, event) {

      var application = this.controllerFor('application');
      var bauteileController = this.controllerFor('bauteile');
      var x3d = application.get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');

      var self = this;

      x3d.set('aktuellerSchraubenTyp', 'standardSchraube');
      x3d.set('ergebnisGeladen', false);
      x3d.set('schraubenlisteAktualisiert', !x3d.get('schraubenlisteAktualisiert'));

      value = parseFloat(value).toFixed(1);

      switch (event.target.name) {
        case 'setztiefe':
          if (this.errors.setztiefe.length === 0) {
            bauteile.findBy('id', 'schraubeStandard1').get('screwProperties').objectAt(0).set('setztiefe', value / Number(self.get('laengenFaktor')));
            bauteile.findBy('id', 'schraubeStandard2').get('screwProperties').objectAt(0).set('setztiefe', value / Number(self.get('laengenFaktor')));
            x3d.set('setztiefeEingetragen', true);
            console.log('set x3d setztiefe');
            bauteileController.setSchraubenlaenge();
          } else {
            x3d.set('setztiefeEingetragen', false);
          }
          break;
      }

      x3d.set('transformHelper', !x3d.get('transformHelper'));
      self.controllerFor('application').zentriertObjekt();
    },

    addValueToArray: function addValueToArray(array, value) {
      var self = this;

      if (self.get(array).indexOf(value) === -1) {
        self.get(array).push(value);
      }
    },

    removeValueFromArray: function removeValueFromArray(array, value) {
      var self = this;

      if (self.get(array).indexOf(value) !== -1) {
        self.get(array).splice(self.get(array).indexOf(value), 1);
      }
    },

    actions: {

      validation: function validation(value, event) {

        var self = this;

        if (!self.openFromFile) {

          this.setX3D(value, event);
        }

        self.validate().then(function () {
          // all validations pass

        })['catch'](function () {
          // any validations fail

        })['finally'](function () {

          var validation = self.get('isValid');
          var application = self.controllerFor('application');
          if (validation === true) {
            console.log("validation true");
            self.set('displayErrors', false);
            application.set('geometrieInvalid', false);

            var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
            applicationdata.set('treeLoaded', false);
            applicationdata.set('initialized', false);
            applicationdata.set('pdfErstellt', false);
            applicationdata.set('dxfErstellt', false);

            var downloadcenter = self.controllerFor('downloadcenter');
            downloadcenter.set('pdfErstellt', false);
            downloadcenter.set('dxfErstellt', false);

            application.set('verbindungsmittelInvalid', true);

            var geometrie = self.controllerFor('application').get('model').geometrie.objectAt(0);
            geometrie.set('Setztiefe', (parseFloat(self.get('setztiefe')).toFixed(1) / self.laengenFaktor).toFixed(1));
          } else {
            self.set('displayErrors', true);
            application.set('geometrieInvalid', true);
          }
        });
      },

      setztiefeIsSelected: function setztiefeIsSelected() {
        var setztiefeAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('setztiefeAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('setztiefeAktiv', !setztiefeAktiv);

        if (this.get('setztiefe') !== "") {
          var setztiefework = parseFloat(this.get('setztiefe').replace(",", "."));
          var setztieferounded = setztiefework.toFixed(this.laengenNachkomma);
          console.log(this.laengenNachkomma + " //// " + setztieferounded);
          this.set('setztiefe', setztieferounded);
          this.send('validation', setztieferounded, {
            target: {
              name: "setztiefe"
            }
          });
        }

        if (setztiefeAktiv === false) {
          document.getElementsByName('setztiefe')[0].setSelectionRange(0, this.get('setztiefe').length);
        }
      },

      removeClass: function removeClass(element) {
        var self = this;
        self.set(element, false);
      }

    },

    // hilfeTexte observes

    observes_scherflaechenVMHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Geometrie", "scherflaechenVM", "0");
      self.controllerFor("supercontroller").setHilfetexte("geometrie", hilfetextArray);
    }).observes('scherflaechenVM', 'initTrigger'),

    observes_scherflaechenAHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Geometrie", "scherflaechenA", "0");
      self.controllerFor("supercontroller").setHilfetexte("geometrie", hilfetextArray);
    }).observes('scherflaechenA', 'initTrigger'),

    observes_schichtfolgeHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Geometrie", "schichtfolge", "0");
      self.controllerFor("supercontroller").setHilfetexte("geometrie", hilfetextArray);
    }).observes('schichtfolge', 'initTrigger'),

    observes_setztiefeHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Geometrie", "setztiefe", "0");
      self.controllerFor("supercontroller").setHilfetexte("geometrie", hilfetextArray);
    }).observes('setztiefe', 'initTrigger'),

    observes_zwischenschichtVerstaerkungHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Geometrie", "zwischenschichtVerstaerkung", "0");
      self.controllerFor("supercontroller").setHilfetexte("geometrie", hilfetextArray);
    }).observes('zwischenschichtVerstaerkung', 'initTrigger'),

    // hilfeTexte display properties

    display_scherflaechenVMHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('scherflaechenVMHilfetext'));;
    }).property('scherflaechenVMHilfetext'),

    display_scherflaechenAHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('scherflaechenAHilfetext'));;
    }).property('scherflaechenAHilfetext'),

    display_schichtfolgeHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('schichtfolgeHilfetext'));;
    }).property('schichtfolgeHilfetext'),

    display_setztiefeHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('setztiefeHilfetext'));;
    }).property('setztiefeHilfetext'),

    display_zwischenschichtVerstaerkungHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('zwischenschichtVerstaerkungHilfetext'));;
    }).property('zwischenschichtVerstaerkungHilfetext')

  });

});