define('m03/controllers/bauteile', ['exports', 'ember', 'ember-validations', 'ember-i18n'], function (exports, Ember, EmberValidations, ember_i18n) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(EmberValidations['default'], {

    initTrigger: false,

    itw: false, //don't change this!
    swg: false, //don't change this!
    firm: "WUERTH", //don't change this!

    reiterAnschlussgeometrieAnzeigen: false, //don't change this!

    timberCode: "EC 5 NAD (D)", //don't change this!

    debug: true,
    openFromFile: false,
    c26: false,
    displayErrors: true,
    material: {},
    honk: null,

    displaySchalungTragUndKonterlattung: true,

    // ttHoeheHT: Ember.computed('i18n.locale', function () {
    //
    //   return this.get('i18n').t('wertebereich') + "2.0 - 9999.9 [cm]";
    //
    // }),
    ttBreiteHT: Ember['default'].computed('i18n.locale', {

      get: function get(key) {
        return this.get('i18n').t('wertebereich') + " 2.4 - 9999.9 [cm]";
      },
      set: function set(key, value) {
        return value;
      }

    }),
    ttHoeheHT: Ember['default'].computed('i18n.locale', {

      get: function get(key) {
        return this.get('i18n').t('wertebereich') + " 2.0 - 9999.9 [cm]";
      },
      set: function set(key, value) {
        return value;
      }

    }),
    ttBreiteNT: Ember['default'].computed('i18n.locale', {

      get: function get(key) {
        return this.get('i18n').t('wertebereich') + " 2.4 - 9999.9 [cm]";
      },
      set: function set(key, value) {
        return value;
      }

    }),
    ttHoeheNT: Ember['default'].computed('i18n.locale', {

      get: function get(key) {
        return this.get('i18n').t('wertebereich') + " 2.0 - 9999.9 [cm]";
      },
      set: function set(key, value) {
        return value;
      }

    }),
    ttWinkel: Ember['default'].computed('i18n.locale', {

      get: function get(key) {
        return this.get('i18n').t('wertebereich') + " 0.0 - 90.0 [°]";
      },
      set: function set(key, value) {
        return value;
      }

    }),

    i18n: Ember['default'].inject.service(),
    self: undefined,
    haupthauptTraeger1: '',
    htbreite: "",
    hthoehe: "",
    ntbreite: "",
    nthoehe: "",
    toolTip: "test tooltip",
    htfkl: 5,
    ntfkl: 5,
    htholz: 0,
    ntholz: 0,
    winkel: "",
    ntStahl: false,
    htStahl: false,
    laubholz: false,
    korrosionsrisiko: false,

    //-- folgende werte nicht anpassen --
    laengenFaktor: 1, // 1 = default (cm). dieser wert ändert sich dynamisch und sollte nicht händisch geändert werden
    laengenLabel: "cm", // cm = default. dieser wert ändert sich dynamisch und sollte nicht händisch geändert werden
    laengenNachkomma: 1, // 1 = default (cm). dieser wert ändert sich dynamisch und sollte nicht händisch geändert werden
    syslaengenFaktor: 100, // 100 = default (m). dieser wert ändert sich dynamisch und sollte nicht händisch geändert werden
    syslaengenLabel: "m", // m = default. dieser wert ändert sich dynamisch und sollte nicht händisch geändert werden
    syslaengenNachkomma: 3, // 3 = default (m). dieser wert ändert sich dynamisch und sollte nicht händisch geändert werden
    kraefteFaktor: 1, // 1 = default (kN). dieser wert ändert sich dynamisch und sollte nicht händisch geändert werden
    kraefteLabel: "kN", // kN = default. dieser wert ändert sich dynamisch und sollte nicht händisch geändert werden
    kraefteNachkomma: 2, // 2 = default (kN). dieser wert ändert sich dynamisch und sollte nicht händisch geändert werden
    //--

    materialienOption: 1,

    validations: {
      htbreite: {
        type: "l",
        tooltip: "ttBreiteHT",
        numericality: {
          greaterThanOrEqualTo: 1.8,
          lessThanOrEqualTo: 9999.9
        }
      },
      hthoehe: {
        type: "l",
        tooltip: "ttHoeheHT",
        numericality: {
          greaterThanOrEqualTo: 2.0,
          lessThanOrEqualTo: 9999.9
        }
      },
      ntbreite: {
        type: "l",
        tooltip: "ttBreiteNT",
        numericality: {
          greaterThanOrEqualTo: 1.8,
          lessThanOrEqualTo: 9999.9
        }
      },
      nthoehe: {
        type: "l",
        tooltip: "ttHoeheNT",
        numericality: {
          greaterThanOrEqualTo: 2.0,
          lessThanOrEqualTo: 9999.9
        }
      },
      winkel: {
        type: "w",
        tooltip: "ttWinkel",
        numericality: {
          greaterThanOrEqualTo: 0,
          lessThanOrEqualTo: 90.0
        }
      }
    },

    traeger: [{
      name: "Haupt-/Nebenträger",
      value: true
    }, {
      name: "Stütze/Nebenträger",
      value: false
    }],

    htmaterialStahl: [{
      name: "Stahl",
      ind: 0
    }],

    htmaterial: [],
    ntmaterial: [],

    htfklarray: [],
    ntfklarray: [],

    vgebohrt: [],
    te1vorgebohrt: false,
    te2vorgebohrt: false,
    te1laubholz: false,
    te2laubholz: false,

    variante1focused: true,
    variante2focused: false,
    variante3focused: true,
    variante4focused: false,

    minimalDickenDB: {
      0: 2.4,
      1: 2.4,
      13: 2.4,
      21: 0.6,
      20: 0.6,
      7: 0.6,
      8: 0.8,
      12: 2.4,
      1000: 0.1,
      30: 2.4
    },

    htholzHilfetext: "",
    htholzHilfetextFocused: false,
    htfklHilfetext: "",
    htfklHilfetextFocused: false,
    te1vorgebohrtHilfetext: "",
    te1vorgebohrtHilfetextFocused: false,
    htbreiteHilfetext: "",
    htbreiteHilfetextFocused: false,
    hthoeheHilfetext: "",
    hthoeheHilfetextFocused: false,
    winkelHilfetext: "",
    winkelHilfetextFocused: false,
    ntholzHilfetext: "",
    ntholzHilfetextFocused: false,
    ntfklHilfetext: "",
    ntfklHilfetextFocused: false,
    te2vorgebohrtHilfetext: "",
    te2vorgebohrtHilfetextFocused: false,
    ntbreiteHilfetext: "",
    ntbreiteHilfetextFocused: false,
    nthoeheHilfetext: "",
    nthoeheHilfetextFocused: false,

    schalungTragUndKonterlattung: false,

    init: function init() {
      this._super();
      var self = this;

      this.set('htbreite', "");
      this.set('hthoehe', "");
      this.set('ntbreite', "");
      this.set('nthoehe', "");
      this.set('winkel', "0.0");

      self.setSelectFieldsContent();

      this.set('haupthauptTraeger1', this.get('i18n').t('bemessungslast').toString());
    },

    applyFactor: function applyFactor(m) {
      console.log("applyfactor(): " + this.laengenFaktor + " / " + this.kraefteFaktor);
      var result = "";
      for (var p in this[m]) {
        console.log("for");
        if (this[m].hasOwnProperty(p)) {
          console.log(p);
          if (this[m][p].type == "l") {
            console.log("laenge wird angepasst");
            this[m][p].numericality.lessThanOrEqualTo = parseFloat(this[m][p].numericality.lessThanOrEqualTo * this.laengenFaktor);
            this[m][p].numericality.greaterThanOrEqualTo = parseFloat(this[m][p].numericality.greaterThanOrEqualTo * this.laengenFaktor);
            // this.set(this[m][p].tooltip, this.get('i18n').t('wertebereich') + parseFloat(this[m][p].numericality.greaterThanOrEqualTo).toFixed(this.laengenNachkomma) + " - " +  parseFloat(this[m][p].numericality.lessThanOrEqualTo).toFixed(this.laengenNachkomma) + " [" + this.laengenLabel + "]");
          } else if (this[m][p].type == "sl") {
              console.log("systemlaenge wird angepasst");
              this[m][p].numericality.lessThanOrEqualTo = parseFloat(this[m][p].numericality.lessThanOrEqualTo * this.syslaengenFaktor);
              this[m][p].numericality.greaterThanOrEqualTo = parseFloat(this[m][p].numericality.greaterThanOrEqualTo * this.syslaengenFaktor);
              // this.set(this[m][p].tooltip, this.get('i18n').t('wertebereich') + parseFloat(this[m][p].numericality.greaterThanOrEqualTo).toFixed(this.syslaengenNachkomma) + " - " +  parseFloat(this[m][p].numericality.lessThanOrEqualTo).toFixed(this.syslaengenNachkomma) + " [" + this.syslaengenLabel + "]");
            } else if (this[m][p].type == "k") {
                console.log("kraft wird angepasst");
                this[m][p].numericality.lessThanOrEqualTo = parseFloat(this[m][p].numericality.lessThanOrEqualTo * this.kraefteFaktor);
                this[m][p].numericality.greaterThanOrEqualTo = parseFloat(this[m][p].numericality.greaterThanOrEqualTo * this.kraefteFaktor);
                // this.set(this[m][p].tooltip, this.get('i18n').t('wertebereich') + parseFloat(this[m][p].numericality.greaterThanOrEqualTo).toFixed(this.kraefteNachkomma) + " - " +  parseFloat(this[m][p].numericality.lessThanOrEqualTo).toFixed(this.kraefteNachkomma) + " [" + this.kraefteLabel + "]");
              }
        }
      }
    },

    setTooltips: function setTooltips(m) {

      console.log('##### setTooltips Bauteile #####');

      var result = "";
      for (var p in this[m]) {
        if (this[m].hasOwnProperty(p)) {
          if (this[m][p].type == "l") {
            this.set(this[m][p].tooltip, this.get('i18n').t('wertebereich') + ' ' + parseFloat(this[m][p].numericality.greaterThanOrEqualTo).toFixed(this.laengenNachkomma) + " - " + parseFloat(this[m][p].numericality.lessThanOrEqualTo).toFixed(this.laengenNachkomma) + " [" + this.laengenLabel + "]");
          } else if (this[m][p].type == "sl") {
            this.set(this[m][p].tooltip, this.get('i18n').t('wertebereich') + ' ' + parseFloat(this[m][p].numericality.greaterThanOrEqualTo).toFixed(this.syslaengenNachkomma) + " - " + parseFloat(this[m][p].numericality.lessThanOrEqualTo).toFixed(this.syslaengenNachkomma) + " [" + this.syslaengenLabel + "]");
          } else if (this[m][p].type == "k") {
            this.set(this[m][p].tooltip, this.get('i18n').t('wertebereich') + ' ' + parseFloat(this[m][p].numericality.greaterThanOrEqualTo).toFixed(this.kraefteNachkomma) + " - " + parseFloat(this[m][p].numericality.lessThanOrEqualTo).toFixed(this.kraefteNachkomma) + " [" + this.kraefteLabel + "]");
          }
        }
      }
    },

    setValues: function setValues(values) {

      var self = this;

      if (self.debug) {
        console.log("values in bauteile: ");
        console.log(values);
      }

      var htbreiteEvent = {
        target: {
          name: "htbreite"
        }
      };
      var hthoeheEvent = {
        target: {
          name: "hthoehe"
        }
      };
      var ntbreiteEvent = {
        target: {
          name: "ntbreite"
        }
      };
      var nthoeheEvent = {
        target: {
          name: "nthoehe"
        }
      };
      var winkelEvent = {
        target: {
          name: "winkel"
        }
      };

      var setztiefeEvent = {
        target: {
          name: "setztiefe"
        }
      };

      this.setX3D(parseInt(values.Setztiefe), setztiefeEvent);

      this.set('openFromFile', true);

      this.set('htbreite', (values.TimberElement1_b * self.laengenFaktor).toFixed(self.laengenNachkomma));
      this.setX3D((values.TimberElement1_b * self.laengenFaktor).toFixed(self.laengenNachkomma), htbreiteEvent);

      this.set('hthoehe', (values.TimberElement1_h * self.laengenFaktor).toFixed(self.laengenNachkomma));
      this.setX3D((values.TimberElement1_h * self.laengenFaktor).toFixed(self.laengenNachkomma), hthoeheEvent);

      this.set('winkel', values.ALFA_Elements_GRAD);
      this.setX3D(values.ALFA_Elements_GRAD, winkelEvent);

      if (values.ConnectionType === "HH" || values.ConnectionType === "HHH" || values.ConnectionType === "HSH") {
        this.set('htholz', Number(values.MatKeyTimberElement1));
        this.set('htfkl', Number(values.FKLKeyTimberElement1));

        this.set('ntholz', Number(values.MatKeyTimberElement2));
        this.set('ntfkl', Number(values.FKLKeyTimberElement2));
      } else {
        this.set('htholz', Number(values.MatKeyTimberElement2));
        this.set('htfkl', Number(values.FKLKeyTimberElement2));

        this.set('ntholz', Number(values.MatKeyTimberElement1));
        this.set('ntfkl', Number(values.FKLKeyTimberElement1));
      }

      this.set('ntbreite', (values.TimberElement2_b * self.laengenFaktor).toFixed(self.laengenNachkomma));
      this.setX3D((values.TimberElement2_b * self.laengenFaktor).toFixed(self.laengenNachkomma), ntbreiteEvent);

      this.set('nthoehe', (values.TimberElement2_h * self.laengenFaktor).toFixed(self.laengenNachkomma));
      this.setX3D((values.TimberElement2_h * self.laengenFaktor).toFixed(self.laengenNachkomma), nthoeheEvent);

      this.set('korrosionsrisiko', values.CorrosionResistanceNecessary);

      this.set('te1vorgebohrt', values.TimberElement1Predrilled);
      this.set('te2vorgebohrt', values.TimberElement2Predrilled);

      this.send('validation');

      this.set('openFromFile', false);
    },

    setSelectFieldsContent: function setSelectFieldsContent() {
      var self = this;
      self.setHtMaterialien();
      self.setHtFKL();
      self.setNtMaterialien();
      self.setNtFKL();
      self.setVgebohrt();
    },

    setHtMaterialien: function setHtMaterialien() {
      var self = this;
      var indices = new Array();

      if (self.get('firm') === 'ITW' && self.controllerFor("application").get('model').application.objectAt(0).get('timberNA') !== "DE") {
        indices = materialienDB.m03.findBy('firm', self.get('firm')).indices.seitenbauteil.materialien.findBy('id', self.get('materialienOption')).alternativeValues;
      } else {
        indices = materialienDB.m03.findBy('firm', self.get('firm')).indices.seitenbauteil.materialien.findBy('id', self.get('materialienOption')).values;
      }

      // if (self.get('materialienOption') === 1) {
      //   indices = [0, 1, 13, 30, 21, 20, 7, 8, 12];
      // } else if (self.get('materialienOption') === 2) {
      //     indices = [1000];
      // } else if (self.get('materialienOption') === 3) {
      //     indices = [0, 1, 13, 30, 21, 20, 7, 8, 12];
      // } else if (self.get('materialienOption') === 4) {
      //     indices = [0, 1, 13, 30, 12];
      // } else if (self.get('materialienOption') === 5) {
      //     indices = [1000];
      // } else if (self.get('materialienOption') === 6) {
      //     indices = [0, 1, 13, 30, 12];
      // }

      self.set('htmaterial', self.getSelectFieldContent('materialienDB', indices));
    },

    setHtFKL: function setHtFKL() {
      var self = this;
      var indices = [];

      indices = materialienDB.m03.findBy('firm', self.get('firm')).indices.seitenbauteil.festigkeitsklassen.findBy('id', self.get('htholz')).values;

      self.set('htfkl', materialienDB.m03.findBy('firm', self.get('firm')).indices.seitenbauteil.festigkeitsklassen.findBy('id', self.get('htholz')).defaultValue);

      if (this.get('htholz') === 1) {
        // indices = [20, 21, 24, 22];
        self.set('htfklarray', self.getSelectFieldContent('fkl_bsh', indices));
        // self.set('htfkl', 20);
      } else if (self.get('htholz') === 13) {
          // indices = [30, 31, 34, 32];
          self.set('htfklarray', self.getSelectFieldContent('fkl_bsh_kombi', indices));
          // self.set('htfkl', 30);
        } else if (this.get('htholz') === 0) {
            // indices = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
            self.set('htfklarray', self.getSelectFieldContent('fkl_vh', indices));
            // self.set('htfkl', 5);
          } else if (this.get('htholz') === 30) {
              // indices = [301];
              self.set('htfklarray', self.getSelectFieldContent('fkl_baubuche', indices));
              // self.set('htfkl', 301);
            } else if (this.get('htholz') === 21) {
                // indices = [110, 111, 112, 113];
                self.set('htfklarray', self.getSelectFieldContent('fkl_baufurniersperrholz', indices));
                // self.set('htfkl', 110);
              } else if (this.get('htholz') === 20) {
                  // indices = [125, 126, 127];
                  self.set('htfklarray', self.getSelectFieldContent('fkl_osb_platten', indices));
                  // self.set('htfkl', 125);
                } else if (this.get('htholz') === 7) {
                    // indices = [160, 170, 180, 190];
                    self.set('htfklarray', self.getSelectFieldContent('fkl_kunstharzgebundene_holzspanplatten', indices));
                    // self.set('htfkl', 160);
                  } else if (this.get('htholz') === 8) {
                      // indices = [200];
                      self.set('htfklarray', self.getSelectFieldContent('fkl_zementgebundene_holzspanplatten', indices));
                      // self.set('htfkl', 200);
                    } else if (this.get('htholz') === 12) {
                        // indices = [50, 51, 52];
                        self.set('htfklarray', self.getSelectFieldContent('fkl_vollholz_laubholz', indices));
                        // self.set('htfkl', 50);
                      } else if (this.get('htholz') === 1000) {
                          // indices = [23.5, 35.5];
                          self.set('htfklarray', self.getSelectFieldContent('fkl_stahl', indices));
                          // self.set('htfkl', 23.5);
                        } else if (this.get('htholz') === 9) {
                            // indices = [23.5, 35.5];
                            self.set('htfklarray', self.getSelectFieldContent('fkl_holzfaserplatten', indices));
                            // self.set('htfkl', 23.5);
                          } else if (this.get('htholz') === 22) {
                              // indices = [23.5, 35.5];
                              self.set('htfklarray', self.getSelectFieldContent('fkl_gipskartonplatten', indices));
                              // self.set('htfkl', 23.5);
                            }
    },

    setVgebohrt: function setVgebohrt() {
      var self = this;
      var indices = [false, true];
      self.set('vgebohrt', self.getSelectFieldContent('vgebohrt', indices));
    },

    setNtMaterialien: function setNtMaterialien() {
      var self = this;
      var indices = new Array();

      if (self.get('firm') === 'ITW' && self.get('timberCode') !== "EC 5 NAD (D)") {
        indices = materialienDB.m03.findBy('firm', self.get('firm')).indices.mittelbauteil.materialien.findBy('id', self.get('materialienOption')).alternativeValues;
      } else {
        indices = materialienDB.m03.findBy('firm', self.get('firm')).indices.mittelbauteil.materialien.findBy('id', self.get('materialienOption')).values;
      }

      // if (self.get('materialienOption') === 1) {
      //   indices = [0, 1, 13, 30, 12];
      // } else if (self.get('materialienOption') === 2) {
      //     indices = [0, 1, 13, 30, 12];
      // } else if (self.get('materialienOption') === 3) {
      //     indices = [0, 1, 13, 30, 12];
      // } else if (self.get('materialienOption') === 4) {
      //     indices = [0, 1, 13, 30, 21, 20, 7, 8, 12];
      // } else if (self.get('materialienOption') === 5) {
      //     indices = [0, 1, 13, 30, 12];
      // } else if (self.get('materialienOption') === 6) {
      //     indices = [1000];
      // }

      self.set('ntmaterial', self.getSelectFieldContent('materialienDB', indices));
    },

    setNtFKL: function setNtFKL() {
      var self = this;
      var indices = [];

      indices = materialienDB.m03.findBy('firm', self.get('firm')).indices.mittelbauteil.festigkeitsklassen.findBy('id', this.get('ntholz')).values;
      self.set('ntfkl', materialienDB.m03.findBy('firm', self.get('firm')).indices.mittelbauteil.festigkeitsklassen.findBy('id', this.get('ntholz')).defaultValue);

      if (this.get('ntholz') === 1) {
        // indices = [20, 21, 24, 22];
        self.set('ntfklarray', self.getSelectFieldContent('fkl_bsh', indices));
        // self.set('htfkl', 20);
      } else if (self.get('ntholz') === 13) {
          // indices = [30, 31, 34, 32];
          self.set('ntfklarray', self.getSelectFieldContent('fkl_bsh_kombi', indices));
          // self.set('htfkl', 30);
        } else if (this.get('ntholz') === 0) {
            // indices = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
            self.set('ntfklarray', self.getSelectFieldContent('fkl_vh', indices));
            // self.set('htfkl', 5);
          } else if (this.get('ntholz') === 30) {
              // indices = [301];
              self.set('ntfklarray', self.getSelectFieldContent('fkl_baubuche', indices));
              // self.set('htfkl', 301);
            } else if (this.get('ntholz') === 21) {
                // indices = [110, 111, 112, 113];
                self.set('ntfklarray', self.getSelectFieldContent('fkl_baufurniersperrholz', indices));
                // self.set('htfkl', 110);
              } else if (this.get('ntholz') === 20) {
                  // indices = [125, 126, 127];
                  self.set('ntfklarray', self.getSelectFieldContent('fkl_osb_platten', indices));
                  // self.set('htfkl', 125);
                } else if (this.get('ntholz') === 7) {
                    // indices = [160, 170, 180, 190];
                    self.set('ntfklarray', self.getSelectFieldContent('fkl_kunstharzgebundene_holzspanplatten', indices));
                    // self.set('htfkl', 160);
                  } else if (this.get('ntholz') === 8) {
                      // indices = [200];
                      self.set('ntfklarray', self.getSelectFieldContent('fkl_zementgebundene_holzspanplatten', indices));
                      // self.set('htfkl', 200);
                    } else if (this.get('ntholz') === 12) {
                        // indices = [50, 51, 52];
                        self.set('ntfklarray', self.getSelectFieldContent('fkl_vollholz_laubholz', indices));
                        // self.set('htfkl', 50);
                      } else if (this.get('ntholz') === 1000) {
                          // indices = [23.5, 35.5];
                          self.set('ntfklarray', self.getSelectFieldContent('fkl_stahl', indices));
                          // self.set('htfkl', 23.5);
                        } else if (this.get('ntholz') === 9) {
                            // indices = [23.5, 35.5];
                            self.set('ntfklarray', self.getSelectFieldContent('fkl_holzfaserplatten', indices));
                            // self.set('htfkl', 23.5);
                          } else if (this.get('ntholz') === 22) {
                              // indices = [23.5, 35.5];
                              self.set('ntfklarray', self.getSelectFieldContent('fkl_gipskartonplatten', indices));
                              // self.set('htfkl', 23.5);
                            }
    },

    getSelectFieldContent: function getSelectFieldContent(db, indexes) {
      var self = this;
      var tmp = [];
      var dbContent = self.getDBContent(db);

      for (var i = 0; i < indexes.length; i++) {
        for (var k = 0; k < dbContent.length; k++) {
          if (dbContent[k].id === indexes[i]) {
            tmp.push({ id: dbContent[k].id, name: this.get('i18n').t(dbContent[k].name) });
          }
        }
      }

      return tmp;
    },

    getDBContent: function getDBContent(db) {
      var dbContent = [];
      switch (db) {
        case 'materialienDB':
          dbContent = materialienDB.materialien_de_m03;
          break;
        case 'fkl_vh':
          dbContent = fklDB_new.fkl_vh.fklassen;
          break;
        case 'fkl_bsh':
          dbContent = fklDB_new.fkl_bsh.fklassen;
          break;
        case 'fkl_bsh_kombi':
          dbContent = fklDB_new.fkl_bsh_kombi.fklassen;
          break;
        case 'fkl_baubuche':
          dbContent = fklDB_new.fkl_baubuche.fklassen;
          break;
        case 'fkl_baufurniersperrholz':
          dbContent = fklDB_new.fkl_baufurniersperrholz.fklassen;
          break;
        case 'fkl_osb_platten':
          dbContent = fklDB_new.fkl_osb_platten.fklassen;
          break;
        case 'fkl_kunstharzgebundene_holzspanplatten':
          dbContent = fklDB_new.fkl_kunstharzgebundene_holzspanplatten.fklassen;
          break;
        case 'fkl_zementgebundene_holzspanplatten':
          dbContent = fklDB_new.fkl_zementgebundene_holzspanplatten.fklassen;
          break;
        case 'fkl_vollholz_laubholz':
          dbContent = fklDB_new.fkl_vollholz_laubholz.fklassen;
          break;
        case 'fkl_stahl':
          dbContent = fklDB_new.fkl_stahl.fklassen;
          break;
        case 'fkl_holzfaserplatten':
          dbContent = fklDB_new.fkl_holzfaserplatten.fklassen;
          break;
        case 'fkl_gipskartonplatten':
          dbContent = fklDB_new.fkl_gipskartonplatten.fklassen;
          break;
        case 'vgebohrt':
          dbContent = vorgebohrt.auswahlfeld;
          break;
      }
      return dbContent;
    },

    getValueFromSelectField: function getValueFromSelectField(contentArray, index) {
      var self = this;

      var tmp = "";
      var len = contentArray.length;

      for (var i = 0; i < len; i++) {
        if (contentArray[i].id === index) {
          tmp = contentArray[i].name;
        }
      }
      return tmp;
    },

    watchNumericalInputs: (function () {

      if (!this.openFromFile) {

        if (this.get('htbreite').indexOf(",") !== -1) {
          this.set('htbreite', this.get('htbreite').replace(",", "."));
          this.setfuehrendeNull('htbreite');
        }

        if (this.get('hthoehe').indexOf(",") !== -1) {
          this.set('hthoehe', this.get('hthoehe').replace(",", "."));
          this.setfuehrendeNull('hthoehe');
        }

        if (this.get('ntbreite').indexOf(",") !== -1) {
          this.set('ntbreite', this.get('ntbreite').replace(",", "."));
          this.setfuehrendeNull('ntbreite');
        }

        if (this.get('nthoehe').indexOf(",") !== -1) {
          this.set('nthoehe', this.get('nthoehe').replace(",", "."));
          this.setfuehrendeNull('nthoehe');
        }

        if (this.get('winkel').indexOf(",") !== -1) {
          this.set('winkel', this.get('winkel').replace(",", "."));
          this.setfuehrendeNull('winkel');
        }
      }
    }).observes('htbreite', 'hthoehe', 'ntbreite', 'nthoehe', 'winkel'),

    setfuehrendeNull: function setfuehrendeNull(ind) {
      if (this.get(ind).charAt(0) === '.') {
        this.set(ind, '0.');
      }
    },

    watchHtMaterial: (function () {
      var self = this;

      // if (self.get('htholz') === 12 || self.get('htholz') === 30) {
      //   self.set('te1laubholz', true);
      //   self.set('te1vorgebohrt', true);
      // } else {
      //   self.set('te1laubholz', false);
      // }

      self.set('te1laubholz', vorgebohrt.materialien.findBy('id', this.get('htholz')).laubholz);
      if (self.controllerFor('application').get('model').application.objectAt(0).get('firm') !== "ITW") {
        self.set('te1vorgebohrt', vorgebohrt.materialien.findBy('id', this.get('htholz')).value);
      }

      console.log('***** HTHolz *****');
      console.log(this.get('htholz'));
      // console.log(materialienDB);
      // console.log(vorgebohrt);
      // console.log(vorgebohrt.materialien.findBy('id', this.get('htholz')).value);

      // var tmp = self.minimalDickenDB[self.get('htholz')];
      var tmpMin = materialienDB.m03.findBy('firm', self.get('firm')).indices.seitenbauteil.festigkeitsklassen.findBy('id', this.get('htholz')).minimalDicke;
      var tmpMax = materialienDB.m03.findBy('firm', self.get('firm')).indices.seitenbauteil.festigkeitsklassen.findBy('id', this.get('htholz')).maximalDicke;

      console.log('tmpMin: ' + tmpMin + ' tmpMax: ' + tmpMax);

      console.log('##### htstahl: ' + self.get('htStahl') + ' #####');

      if (self.get('htStahl') === true) {
        self.set('validations.htbreite.numericality.greaterThanOrEqualTo', 0.1 * self.laengenFaktor);
        self.set('validations.htbreite.numericality.lessThanOrEqualTo', 4.0 * self.laengenFaktor);
        //self.set('ttBreiteHT', self.get('i18n').t('wertebereich') + "0.1 - 4 [cm]");
        self.set('ttBreiteHT', this.get('i18n').t('wertebereich') + ' ' + parseFloat(self.validations.htbreite.numericality.greaterThanOrEqualTo).toFixed(this.laengenNachkomma) + " - " + parseFloat(self.validations.htbreite.numericality.lessThanOrEqualTo).toFixed(this.laengenNachkomma) + " [" + this.laengenLabel + "]");
      } else {

        self.set('validations.htbreite.numericality.greaterThanOrEqualTo', tmpMin * self.laengenFaktor);
        self.set('validations.htbreite.numericality.lessThanOrEqualTo', tmpMax * self.laengenFaktor);
        self.set('ttBreiteHT', this.get('i18n').t('wertebereich') + ' ' + parseFloat(self.validations.htbreite.numericality.greaterThanOrEqualTo).toFixed(this.laengenNachkomma) + " - " + parseFloat(self.validations.htbreite.numericality.lessThanOrEqualTo).toFixed(this.laengenNachkomma) + " [" + this.laengenLabel + "]");
      }

      if (self.get('openFromFile') === true) {
        this.send('validation');
      } else {
        this.set('openFromFile', true);
        this.send('validation');
        this.set('openFromFile', false);
      }

      self.setHtFKL();

      self.send("setSchraubenbildWerte", "TE1", "0", "90", "90", "variante1focused", "variante2focused");
    }).observes('htholz'),

    watchNtMaterial: (function () {
      var self = this;

      // if (self.get('ntholz') === 12 || self.get('ntholz') === 30) {
      //   self.set('te2laubholz', true);
      //   self.set('te2vorgebohrt', true);
      // } else {
      //   self.set('te2laubholz', false);
      // }

      self.set('te2laubholz', vorgebohrt.materialien.findBy('id', this.get('ntholz')).laubholz);
      if (self.controllerFor('application').get('model').application.objectAt(0).get('firm') !== "ITW") {
        self.set('te2vorgebohrt', vorgebohrt.materialien.findBy('id', this.get('ntholz')).value);
      }

      // var tmp = self.minimalDickenDB[self.get('ntholz')];
      var tmpMin = materialienDB.m03.findBy('firm', self.get('firm')).indices.mittelbauteil.festigkeitsklassen.findBy('id', this.get('ntholz')).minimalDicke;
      var tmpMax = materialienDB.m03.findBy('firm', self.get('firm')).indices.mittelbauteil.festigkeitsklassen.findBy('id', this.get('ntholz')).maximalDicke;

      self.set('validations.ntbreite.numericality.greaterThanOrEqualTo', tmpMin * self.laengenFaktor);
      self.set('validations.ntbreite.numericality.lessThanOrEqualTo', tmpMax * self.laengenFaktor);
      self.set('ttBreiteNT', this.get('i18n').t('wertebereich') + ' ' + parseFloat(self.validations.ntbreite.numericality.greaterThanOrEqualTo).toFixed(this.laengenNachkomma) + " - " + parseFloat(self.validations.ntbreite.numericality.lessThanOrEqualTo).toFixed(this.laengenNachkomma) + " [" + this.laengenLabel + "]");

      if (self.get('openFromFile') === true) {
        this.send('validation');
      } else {
        this.set('openFromFile', true);
        this.send('validation');
        this.set('openFromFile', false);
      }

      self.setNtFKL();

      self.send("setSchraubenbildWerte", "TE2", "0", "90", "90", "variante3focused", "variante4focused");
    }).observes('ntholz'),

    watchKorrosionsrisiko: (function () {
      var self = this;
      var bauteile = self.controllerFor('application').get('model').bauteile.objectAt(0);

      self.resetVerbindungsmittel();

      bauteile.set('CorrosionResistanceNecessary', self.get('korrosionsrisiko'));
    }).observes('korrosionsrisiko'),

    watchComboboxes: (function () {

      var self = this;

      self.resetVerbindungsmittel();

      // var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
      // applicationdata.set('treeLoaded', false);
      // applicationdata.set('initialized', false);
      // applicationdata.set('pdfErstellt', false);
      // applicationdata.set('dxfErstellt', false);
      //
      // var application = self.controllerFor('application');
      // application.set('verbindungsmittelInvalid', true);
      //
      // var downloadcenter = self.controllerFor('downloadcenter');
      // downloadcenter.set('pdfErstellt', false);
      // downloadcenter.set('dxfErstellt', false);

      var bauteile = this.controllerFor('application').get('model').bauteile.objectAt(0);

      if (self.get('htStahl') === true) {
        bauteile.set('MatKeyTimberElement1', this.get('ntholz'));
        bauteile.set('FKLKeyTimberElement1', this.get('ntfkl'));

        bauteile.set('MatKeyTimberElement2', this.get('htholz'));
        bauteile.set('FKLKeyTimberElement2', this.get('htfkl'));
      } else {
        bauteile.set('MatKeyTimberElement1', this.get('htholz'));
        bauteile.set('FKLKeyTimberElement1', this.get('htfkl'));

        bauteile.set('MatKeyTimberElement2', this.get('ntholz'));
        bauteile.set('FKLKeyTimberElement2', this.get('ntfkl'));
      }

      self.controllerFor('lasteinwirkung').setNklassen();
      self.controllerFor('lasteinwirkung').send('validation', self.controllerFor('lasteinwirkung').get('winkelScherkraft'), { target: { name: "winkelScherkraft" } });
    }).observes('htholz', 'htfkl', 'ntholz', 'ntfkl'),

    watchTe1Vorgebohrt: (function () {

      var self = this;

      self.resetVerbindungsmittel();

      var bauteile = self.controllerFor('application').get('model').bauteile.objectAt(0);
      bauteile.set('TimberElement1Predrilled', self.get('te1vorgebohrt'));
    }).observes('te1vorgebohrt'),

    watchTe2Vorgebohrt: (function () {

      var self = this;

      self.resetVerbindungsmittel();

      var bauteile = self.controllerFor('application').get('model').bauteile.objectAt(0);
      bauteile.set('TimberElement2Predrilled', self.get('te2vorgebohrt'));
    }).observes('te2vorgebohrt'),

    resetVerbindungsmittel: function resetVerbindungsmittel() {
      var self = this;

      var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
      applicationdata.set('treeLoaded', false);
      applicationdata.set('initialized', false);
      applicationdata.set('pdfErstellt', false);
      applicationdata.set('dxfErstellt', false);

      var application = self.controllerFor('application');
      application.set('verbindungsmittelInvalid', true);

      var downloadcenter = self.controllerFor('downloadcenter');
      downloadcenter.set('pdfErstellt', false);
      downloadcenter.set('dxfErstellt', false);

      var x3d = application.get('model').x3ddefault.objectAt(0);
      x3d.set('ergebnisGeladen', false);
      x3d.set('aktuellerSchraubenTyp', 'standardSchraube');
      x3d.set('schraubenlisteAktualisiert', !x3d.get('schraubenlisteAktualisiert'));
    },

    setX3D: function setX3D(value, event) {

      var application = this.controllerFor('application');
      var anschlussgeometrie = this.controllerFor('anschlussgeometrie');
      var x3d = application.get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');

      var self = this;

      var anschlgeom = self.controllerFor('anschlussgeometrie');

      value = parseFloat(value).toFixed(2);

      switch (event.target.name) {
        case 'ntbreite':
          if (this.errors.ntbreite.length === 0) {
            bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).set('z', value / Number(self.get('laengenFaktor')));
            application.get('model').x3ddefault.objectAt(0).set('ntBreiteEingetragen', true / Number(self.get('laengenFaktor')));
            self.setSchraubenlaenge();
          } else {
            application.get('model').x3ddefault.objectAt(0).set('ntBreiteEingetragen', false);
          }
          break;
        case 'nthoehe':
          if (this.errors.nthoehe.length === 0) {
            bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).set('y', value / Number(self.get('laengenFaktor')));
            bauteile.findBy('id', 'verstaerkungBlass1').get('boxsizes').objectAt(0).set('y', value / Number(self.get('laengenFaktor')));
            bauteile.findBy('id', 'verstaerkungBlass2').get('boxsizes').objectAt(0).set('y', value / Number(self.get('laengenFaktor')));

            // anschlgeom.set('validations.b1.numericality.greaterThanOrEqualTo', -(Number(value)-4)/Number(self.get('laengenFaktor')));
            // anschlgeom.set('validations.b1.numericality.lessThanOrEqualTo', 99.9/Number(self.get('laengenFaktor')));
            //
            // anschlgeom.set('ttb1', this.get('i18n').t('wertebereich') +' '+ parseFloat(anschlgeom.validations.b1.numericality.greaterThanOrEqualTo).toFixed(this.laengenNachkomma) + " - " +  parseFloat(anschlgeom.validations.b1.numericality.lessThanOrEqualTo).toFixed(this.laengenNachkomma) + " [" + this.laengenLabel + "]");

            application.get('model').x3ddefault.objectAt(0).set('ntHoeheEingetragen', true);
            if (self.get('itw') === true) {
              anschlussgeometrie.setMindestueberstand();
            }
          } else {
            application.get('model').x3ddefault.objectAt(0).set('ntHoeheEingetragen', false);
          }
          break;
        case 'htbreite':
          if (this.errors.htbreite.length === 0) {
            bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).set('z', value / Number(self.get('laengenFaktor')));
            bauteile.findBy('id', 'hauptTraeger2').get('boxsizes').objectAt(0).set('z', value / Number(self.get('laengenFaktor')));
            application.get('model').x3ddefault.objectAt(0).set('htBreiteEingetragen', true);
            self.setSchraubenlaenge();
          } else {
            application.get('model').x3ddefault.objectAt(0).set('htBreiteEingetragen', false);
          }
          break;
        case 'hthoehe':
          if (this.errors.hthoehe.length === 0) {
            bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).set('y', value / Number(self.get('laengenFaktor')));
            bauteile.findBy('id', 'hauptTraeger2').get('boxsizes').objectAt(0).set('y', value / Number(self.get('laengenFaktor')));
            bauteile.findBy('id', 'verstaerkungWerner1').get('boxsizes').objectAt(0).set('y', value / Number(self.get('laengenFaktor')));
            bauteile.findBy('id', 'verstaerkungWerner2').get('boxsizes').objectAt(0).set('y', value / Number(self.get('laengenFaktor')));

            // anschlgeom.set('validations.a1.numericality.greaterThanOrEqualTo', -(Number(value)-4)/Number(self.get('laengenFaktor')));
            // anschlgeom.set('validations.a1.numericality.lessThanOrEqualTo', 99.9/Number(self.get('laengenFaktor')));
            //
            // anschlgeom.set('validations.a2.numericality.greaterThanOrEqualTo', (-2*Number(value))/Number(self.get('laengenFaktor')));
            // anschlgeom.set('validations.a2.numericality.lessThanOrEqualTo', (2*Number(value))/Number(self.get('laengenFaktor')));

            // anschlgeom.set('tta1', this.get('i18n').t('wertebereich') +' '+ parseFloat(anschlgeom.validations.a1.numericality.greaterThanOrEqualTo).toFixed(this.laengenNachkomma) + " - " +  parseFloat(anschlgeom.validations.a1.numericality.lessThanOrEqualTo).toFixed(this.laengenNachkomma) + " [" + this.laengenLabel + "]");
            // anschlgeom.set('tta2', this.get('i18n').t('wertebereich') +' '+ parseFloat(anschlgeom.validations.a2.numericality.greaterThanOrEqualTo).toFixed(this.laengenNachkomma) + " - " +  parseFloat(anschlgeom.validations.a2.numericality.lessThanOrEqualTo).toFixed(this.laengenNachkomma) + " [" + this.laengenLabel + "]");

            if (self.get('itw') === true) {
              anschlussgeometrie.setMindestueberstand();
            }
            application.get('model').x3ddefault.objectAt(0).set('htHoeheEingetragen', true);
          } else {
            application.get('model').x3ddefault.objectAt(0).set('htHoeheEingetragen', false);
          }
          break;
        case 'winkel':
          if (this.errors.winkel.length === 0) {
            x3d.set('vWinkel', value);
            anschlussgeometrie.set('winkel', value);
            x3d.set('vWinkelEingetragen', true);
          } else {
            x3d.set('vWinkelEingetragen', false);
          }
          break;
      }

      if (application.get('swg') === false && self.get('itw') === true) {
        anschlussgeometrie.setMindestueberstand();
      }

      this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('transformHelper', !application.get('model').x3ddefault.objectAt(0).get('transformHelper'));
      this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('arc2dPositionTriggern', !application.get('model').x3ddefault.objectAt(0).get('arc2dPositionTriggern'));
      self.controllerFor('application').zentriertObjekt();
    },

    setSchraubenlaenge: function setSchraubenlaenge() {
      var application = this.controllerFor('application');
      var x3d = application.get('model').x3ddefault.objectAt(0);
      var bauteile = application.get('model').x3ddefault.objectAt(0).get('bauteile');

      var htBreite = bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('z');
      var ntBreite = bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('z');

      var verstaerkung = 0;

      if (x3d.get('verstaerkungBlass') === true && x3d.get('verstaerkungWerner') === false) {
        verstaerkung = Number(x3d.get('dickeBlass'));
      } else if (x3d.get('verstaerkungBlass') === true && (x3d.get('verstaerkungWerner') === true && x3d.get('sFolgeHolzHolz') === true)) {
        verstaerkung = Number(x3d.get('dickeWerner')) * 2;
      } else if (x3d.get('verstaerkungBlass') === true && (x3d.get('verstaerkungWerner') === true && x3d.get('sFolgeHolzHolz') === false)) {
        verstaerkung = Number(x3d.get('dickeWerner'));
      }

      var schraubenLaenge = 0;
      var geometrie = this.controllerFor('geometrie');
      var setztiefe = Number(geometrie.get('setztiefe')) / Number(this.get('laengenFaktor'));

      if (x3d.get('zweiSchnittig') === true) {
        schraubenLaenge = Number(ntBreite) + Number(verstaerkung) + Number(htBreite) + Number(verstaerkung) + Number(ntBreite) / 2 - Number(setztiefe);
        var z = 0 - (Number(htBreite) / 2 + Number(verstaerkung) + Number(ntBreite) / 2);
        bauteile.findBy('id', 'schraubeStandard1').get('translations').objectAt(0).set('z', z);
      } else {
        schraubenLaenge = Number(ntBreite) + Number(verstaerkung) + Number(htBreite) / 2 - Number(setztiefe);
        bauteile.findBy('id', 'schraubeStandard1').get('translations').objectAt(0).set('z', 0);
      }

      bauteile.findBy('id', 'schraubeStandard1').get('screwProperties').objectAt(0).set('length', schraubenLaenge);
      bauteile.findBy('id', 'schraubeStandard1').get('screwProperties').objectAt(0).set('threadlengthtip', schraubenLaenge);
      bauteile.findBy('id', 'schraubeStandard2').get('screwProperties').objectAt(0).set('length', schraubenLaenge);
      bauteile.findBy('id', 'schraubeStandard2').get('screwProperties').objectAt(0).set('threadlengthtip', schraubenLaenge);
    },

    htBauBuche: (function () {
      if (Number(this.get('htholz')) === 30) {
        return true;
      }
      return false;
    }).property('htholz'),

    ntBauBuche: (function () {
      if (Number(this.get('ntholz')) === 30) {
        return true;
      }
      return false;
    }).property('ntholz'),

    actions: {

      validation: function validation(value, event) {

        var self = this;

        if (!self.get('openFromFile')) {
          this.setX3D(value, event);
        }

        self.validate().then(function () {
          // all validations pass

        })['catch'](function () {
          // any validations fail

        })['finally'](function () {

          var validation = self.get('isValid');
          var application = self.controllerFor('application');
          if (validation === true) {
            self.set('displayErrors', false);
            application.set('bauteileInvalid', false);

            self.resetVerbindungsmittel();

            // var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
            // applicationdata.set('treeLoaded', false);
            // applicationdata.set('initialized', false);
            // applicationdata.set('pdfErstellt', false);
            // applicationdata.set('dxfErstellt', false);
            //
            // var downloadcenter = self.controllerFor('downloadcenter');
            // downloadcenter.set('pdfErstellt', false);
            // downloadcenter.set('dxfErstellt', false);
            //
            // application.set('verbindungsmittelInvalid', true);

            var bauteile = self.controllerFor('application').get('model').bauteile.objectAt(0);

            bauteile.set('TimberElement1_b', (parseFloat(self.get('htbreite')).toFixed(2) / self.laengenFaktor).toFixed(2));
            bauteile.set('TimberElement1_h', (parseFloat(self.get('hthoehe')).toFixed(2) / self.laengenFaktor).toFixed(2));
            bauteile.set('TimberElement2_b', (parseFloat(self.get('ntbreite')).toFixed(2) / self.laengenFaktor).toFixed(2));
            bauteile.set('TimberElement2_h', (parseFloat(self.get('nthoehe')).toFixed(2) / self.laengenFaktor).toFixed(2));
            bauteile.set('ALFA_Elements_GRAD', parseFloat(self.get('winkel')).toFixed(2));

            // if(application.get('swg') === false){
            var anschlussgeometrieData = self.controllerFor('application').get('model').anschlussgeometrie.objectAt(0);
            anschlussgeometrieData.set('ALFA_Elements_GRAD', parseFloat(self.get('winkel')).toFixed(1));
            // }
          } else {
              self.set('displayErrors', true);
              application.set('bauteileInvalid', true);
            }
        });
      },

      htbreiteIsSelected: function htbreiteIsSelected() {
        var htbreiteaktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('htbreiteAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('htbreiteAktiv', !htbreiteaktiv);

        if (this.get('htbreite') !== "") {
          var htbreitework = parseFloat(this.get('htbreite').replace(",", "."));
          var htbreiterounded = htbreitework.toFixed(this.laengenNachkomma);
          this.set('htbreite', htbreiterounded);
          this.send('validation', htbreiterounded, {
            target: {
              name: "htbreite"
            }
          });
        }

        if (htbreiteaktiv === false) {
          document.getElementsByName('htbreite')[0].setSelectionRange(0, this.get('htbreite').length);
        }
      },

      hthoeheIsSelected: function hthoeheIsSelected() {
        var hthoeheaktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('hthoeheAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('hthoeheAktiv', !hthoeheaktiv);

        if (this.get('hthoehe') !== "") {
          var hthoehework = parseFloat(this.get('hthoehe').replace(",", "."));
          var hthoeherounded = hthoehework.toFixed(this.laengenNachkomma);
          this.set('hthoehe', hthoeherounded);
          this.send('validation', hthoeherounded, {
            target: {
              name: "hthoehe"
            }
          });
        }

        if (hthoeheaktiv === false) {
          document.getElementsByName('hthoehe')[0].setSelectionRange(0, this.get('hthoehe').length);
        }
      },

      ntbreiteIsSelected: function ntbreiteIsSelected() {
        var ntbreiteaktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('ntbreiteAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('ntbreiteAktiv', !ntbreiteaktiv);

        if (this.get('ntbreite') !== "") {
          var ntbreitework = parseFloat(this.get('ntbreite').replace(",", "."));
          var ntbreiterounded = ntbreitework.toFixed(this.laengenNachkomma);
          this.set('ntbreite', ntbreiterounded);
          this.send('validation', ntbreiterounded, {
            target: {
              name: "ntbreite"
            }
          });
        }

        if (ntbreiteaktiv === false) {
          document.getElementsByName('ntbreite')[0].setSelectionRange(0, this.get('ntbreite').length);
        }
      },

      nthoeheIsSelected: function nthoeheIsSelected() {
        var nthoeheaktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('nthoeheAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('nthoeheAktiv', !nthoeheaktiv);

        if (this.get('nthoehe') !== "") {
          var nthoehework = parseFloat(this.get('nthoehe').replace(",", "."));
          var nthoeherounded = nthoehework.toFixed(this.laengenNachkomma);
          this.set('nthoehe', nthoeherounded);
          this.send('validation', nthoeherounded, {
            target: {
              name: "nthoehe"
            }
          });
        }

        if (nthoeheaktiv === false) {
          document.getElementsByName('nthoehe')[0].setSelectionRange(0, this.get('nthoehe').length);
        }
      },

      winkelIsSelected: function winkelIsSelected(input) {
        var vWinkelAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('vWinkelAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('vWinkelAktiv', !vWinkelAktiv);

        if (this.get('winkel') !== "") {
          var winkelwork = parseFloat(this.get('winkel').replace(",", "."));
          var winkelrounded = winkelwork.toFixed(1);
          this.set('winkel', winkelrounded);
          this.send('validation', winkelrounded, {
            target: {
              name: "winkel"
            }
          });
        }

        if (vWinkelAktiv === false) {
          document.getElementsByName('winkel')[0].setSelectionRange(0, this.get('winkel').length);
        }
      },

      setSchraubenbildWerte: function setSchraubenbildWerte(te, _alphaRAD_KraftFaser, _alphaRAD_vmFaser, _betaRAD_vmFurnier, focusedButton, unfocusedButton) {

        var self = this;

        self.resetVerbindungsmittel();

        var bauteile = this.controllerFor('application').get('model').bauteile.objectAt(0);

        bauteile.set(te + '_alphaRAD_KraftFaser', _alphaRAD_KraftFaser);
        bauteile.set(te + '_alphaRAD_vmFaser', _alphaRAD_vmFaser);
        bauteile.set(te + '_betaRAD_vmFurnier', _betaRAD_vmFurnier);

        self.set(focusedButton, true);
        self.set(unfocusedButton, false);
      },

      removeClass: function removeClass(element) {
        var self = this;
        self.set(element, false);
      }

    }, // End Actions

    // hilfeTexte obeserver

    observes_htholzHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Bauteile", "htholz", self.get('htholz').toString());
      self.controllerFor("supercontroller").setHilfetexte("bauteile", hilfetextArray);
    }).observes('htholz', 'initTrigger'),

    observes_htfklHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Bauteile", "htfkl", "0");
      self.controllerFor("supercontroller").setHilfetexte("bauteile", hilfetextArray);
    }).observes('htfkl', 'initTrigger'),

    observes_te1vorgebohrtHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Bauteile", "te1vorgebohrt", "0");
      if (Number(self.get("htholz")) !== 30) {
        self.controllerFor("supercontroller").setHilfetexte("bauteile", hilfetextArray);
      }
    }).observes('te1vorgebohrt', 'initTrigger'),

    observes_htbreiteHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Bauteile", "htbreite", "0");
      self.controllerFor("supercontroller").setHilfetexte("bauteile", hilfetextArray);
    }).observes('htnreite', 'initTrigger'),

    observes_hthoeheHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Bauteile", "hthoehe", "0");
      self.controllerFor("supercontroller").setHilfetexte("bauteile", hilfetextArray);
    }).observes('hthoehe', 'initTrigger'),

    observes_ntholzHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Bauteile", "ntholz", self.get('ntholz').toString());
      self.controllerFor("supercontroller").setHilfetexte("bauteile", hilfetextArray);
    }).observes('ntholz', 'initTrigger'),

    observes_ntfklHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Bauteile", "ntfkl", "0");
      self.controllerFor("supercontroller").setHilfetexte("bauteile", hilfetextArray);
    }).observes('ntfkl', 'initTrigger'),

    observes_te2vorgebohrtHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Bauteile", "te2vorgebohrt", "0");
      if (Number(self.get("ntholz")) !== 30) {
        self.controllerFor("supercontroller").setHilfetexte("bauteile", hilfetextArray);
      }
    }).observes('te2vorgebohrt', 'initTrigger'),

    observes_ntbreiteHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Bauteile", "ntbreite", "0");
      self.controllerFor("supercontroller").setHilfetexte("bauteile", hilfetextArray);
    }).observes('ntbreite', 'initTrigger'),

    observes_nthoeheHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Bauteile", "nthoehe", "0");
      self.controllerFor("supercontroller").setHilfetexte("bauteile", hilfetextArray);
    }).observes('nthoehe', 'initTrigger'),

    // hilfeTexte display properties

    display_htholzHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('htholzHilfetext'));
    }).property('htholzHilfetext'),

    display_htfklHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('htfklHilfetext'));
    }).property('htfklHilfetext'),

    display_te1vorgebohrtHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('te1vorgebohrtHilfetext'));
    }).property('te1vorgebohrtHilfetext'),

    display_htbreiteHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('htbreiteHilfetext'));
    }).property('htbreiteHilfetext'),

    display_hthoeheHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('hthoeheHilfetext'));
    }).property('hthoeheHilfetext'),

    display_ntholzHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('ntholzHilfetext'));
    }).property('ntholzHilfetext'),

    display_ntfklHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('ntfklHilfetext'));
    }).property('ntfklHilfetext'),

    display_te2vorgebohrtHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('te2vorgebohrtHilfetext'));
    }).property('te2vorgebohrtHilfetext'),

    display_ntbreiteHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('ntbreiteHilfetext'));
    }).property('ntbreiteHilfetext'),

    display_nthoeheHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('nthoeheHilfetext'));
    }).property('nthoeheHilfetext'),

    setDisplaySchalungTragUndKonterlattung: function setDisplaySchalungTragUndKonterlattung() {
      if (this.get('itw') === true && this.controllerFor("geometrie").get('stahlholz') === false && this.controllerFor("application").get('model').application.objectAt(0).get('timberNA') === "DE") {
        this.set('displaySchalungTragUndKonterlattung', true);
      } else {
        this.set('displaySchalungTragUndKonterlattung', false);
      }
    },

    watchSchalungTragUndKonterlattung: (function () {
      this.resetVerbindungsmittel();
      this.controllerFor("application").get('model').bauteile.objectAt(0).set('schalungTragUndKonterlattung', this.get('schalungTragUndKonterlattung'));
    }).observes('schalungTragUndKonterlattung')

  });

});